// LIBs
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// API
import { getWebStores, getMenus, addBlock, editBlock } from '../../../core/api';

// HELPERs
import toast from '../../../core/helpers/toast';

// COMPONENTs
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';

// REDUCERs
import { planUser } from '../../../core/reducers/UserReducer/selectors';
import { listWebstore } from '../../../core/reducers/WebstoreReducer/selectors';

const FormBlocksBlock = (props) => {
  const [title, setTitle] = useState('');
  const [webstore, setWebstore] = useState('');
  const [menu, setMenu] = useState('');
  const [menus, setMenus] = useState([]);
  const [planStatus, setPlanStatus] = useState(true);

  useEffect(() => {
    initilize();
  }, []);

  useEffect(() => {
    if (props.data && props.data.id) {
      setTitle(props.data.title);
      setWebstore(props.data.webstore_id);
      setMenu(props.data.menu_id);
    } else {
      clear();
    }
  }, [props.data]);

  async function initilize() {
    const menusReq = await getMenus(null);

    if (menusReq && menusReq.data) {
      setMenus(menusReq.data.list);
    }

    if (props.plan) {
      const price = props.plan.price;
      const planStatus = price > 0;
      setPlanStatus(planStatus);
    }
  }

  function clear() {
    setTitle('');
    setWebstore('');
    setMenu('');
  }

  function send() {
    if (props.data.id) {
      sendUpdate();
    } else {
      if (props.blocks.list.length) {
        if (planStatus) {
          sendAdd();
        } else {
          toast('warning', 'Seu plano é gratuito e não permite fazer mais um registro!');
        }
      } else {
        sendAdd();
      }
    }
  }

  async function sendAdd() {
    if (title && webstore && menu) {
      let params = {
        title,
        webstore_id: webstore,
        menu_id: menu,
      };

      const req = await addBlock(params);

      if (req.error && req.error.title) {
        toast('error', req.error.title);
      }

      if (req.data && req.data.id) {
        toast('success', 'Registrado com sucesso!');
        props.onRequest();
        props.onClear();
        clear();
      }
    } else {
      toast('warning', 'Preencha os campos corretamente!');
    }
  }

  async function sendUpdate() {
    if (props.data.id && title && webstore && menu) {
      let params = {
        title,
        webstore_id: webstore,
        menu_id: menu,
      };

      const req = await editBlock(props.data.id, params);

      if (req.error && req.error.title) {
        toast('error', req.error.title);
      }

      if (req.data && req.data.id) {
        toast('success', 'Atualizado com sucesso!');
        props.onRequest();
        props.onClear();
        clear();
      }
    } else {
      toast('warning', 'Preencha os campos corretamente!');
    }
  }

  return (
    <div className='FormBlocksBlock'>
      <div className='FormBlocksBlock__inputs'>
        <Grid>
          <Input label='Título: *' value={title} onChange={(e) => setTitle(e.target.value)} />
          <Select
            label='Webstores: *'
            value={webstore}
            onChange={(e) => setWebstore(e.target.value)}>
            <option>Selecione um Webstore</option>
            {props.list.length > 0 &&
              props.list.map((element, index) => (
                <option key={index} value={element.id}>
                  {element.name}
                </option>
              ))}
          </Select>
          <Select label='Menu: *' value={menu} onChange={(e) => setMenu(e.target.value)}>
            <option>Selecione um Menu</option>
            {menus.length > 0 &&
              menus
                .filter((element) => element.webstore_id === Number(webstore))
                .map((element, index) => (
                  <option key={index} value={element.id}>
                    {element.title}
                  </option>
                ))}
          </Select>
        </Grid>
      </div>

      <div className='FormBlocksBlock__button'>
        <Button type='button' onClick={send}>
          {props.data.id ? 'Salvar' : 'Registrar'}
        </Button>
        {props.data.id && (
          <Button
            type='button'
            border='outlined'
            onClick={() => {
              clear();
              props.onClear();
            }}>
            Cancelar
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  plan: planUser(state),
  list: listWebstore(state),
});

export default connect(mapStateToProps)(FormBlocksBlock);
