// LIBs
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// CONFIG
import { Login } from '../../../core/api';

// HELPERs
import cn from "../../../core/helpers/cn";

// COMPONENTs
import Input from "../../../components/Input";
import Button from "../../../components/Button";

// REDUCERs
import { setUser } from "../../../core/reducers/UserReducer/actions";
import { changeAbas } from "../../../core/reducers/AbasReducer/actions";
import { changeListWebstore } from "../../../core/reducers/WebstoreReducer/actions";

const FormAccountLogin = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  async function handleClick(event) {
    event.preventDefault();
    eventToast(email, "Preencha o E-mail");
    eventToast(password, "Preencha Senha");

    if (email && password) {
      let params = {
        email: email,
        password: password
      }

      let req = await Login(params);

      if (req.data.token) {
        props.setUser(req.data);
        localStorage.setItem('token', req.data.token);
        props.changeAbas();
        props.changeListWebstore();
        navigate('/');
      }

      if (req.error) {
        toast.error(req.error.title, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  function eventToast(value, text) {
    if (!value) {
      toast.error(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }
  }

  return (
    <div className={cn("FormAccountLogin", props.className)}>
      <form action="" onSubmit={handleClick}>
        <div className="FormAccountLogin__inputs">
          <Input
            label="E-mail"
            type="email"
            className="FormAccountLogin__inputs--control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            label="Senha"
            type="password"
            className="FormAccountLogin__inputs--control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="FormAccountLogin__button">
          <Button type="button">Entrar</Button>
        </div>
      </form>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setUser,
  changeAbas,
  changeListWebstore,
}, dispatch);

export default connect(null, mapDispatchToProps)(FormAccountLogin);
