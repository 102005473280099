export default function navItemActive() {
  const navItems = document.querySelectorAll(".NavItem a");
  if (navItems.length > 0) {
    navItems.forEach(element => {
      element.parentNode.classList.remove("active-nav");
      
      const href = element.getAttribute("href");
      
      if (window.location.href.indexOf(href) > -1) {
        element.parentNode.classList.add("active-nav");
      }
    });
  }
}