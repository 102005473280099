// LIBs
import React from "react";

// COMPONENTs
import Table from "../../../components/Table";

// FORMs
import FormPagesPages from "../../../Forms/Pages/Pages";

const collumns = [
  { label: "ID", name: "id" },
  { label: "Titulo", name: "title" },
  { label: "Identificação", name: "identification" },
  { label: "Status", name: "status" },
]

const rows = [
  { id: 1, title: "Perguntas Frequentes", identification: "menu-home", status: "Ativo" },
  { id: 2, title: "Faq", identification: "menu-servico", status: "Ativo" },
  { id: 3, title: "Contato", identification: "menu-sobre", status: "Inativo" },
];

export default (props) => (
  <div className="Pages">
    <FormPagesPages>
      <Table 
        collumns={collumns}
        rows={rows}
        onEdit={(row) => console.log(row)}
      />
    </FormPagesPages>
  </div>
);