// LIBs
import React, { useState } from "react";

// COMPONENTs
import TextField from "../TextField";

// HELPERs
import cn from "../../core/helpers/cn";

// ICONs
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { TiArrowSortedDown, TiArrowSortedUp  } from "react-icons/ti";
import scrollToPosition from "../../core/helpers/scrollToTop";

export default props => {

  function renderCollumns() {
    return (
      <thead className="TableArea__table--top">
        <tr>
          { (props.collumns && props.collumns.length > 0) &&
            props.collumns.map((item, key) => (
              <th 
              onClick={()=>props.onFilter && props.onFilter(item.name)} 
              key={key}>
                {item.label}{props.filter === item.name && 
                (props.order === "asc" 
                  ? <TiArrowSortedDown/> 
                  : <TiArrowSortedUp/>
                )}
              </th>
            ))
          }
          { props.onView &&
            <th>Detalhes</th>
          }
          { (props.onEdit || props.onDel) &&
            <th>Ação</th>
          }
        </tr>
      </thead>
    );
  }

  function renderRows() {
    return (
      <tbody className="TableArea__table--bottom">
        { (
            props.rows && 
            props.rows.length > 0 && 
            props.collumns && 
            props.collumns.length > 0
          ) &&
          props.rows.map((row, index) => (
            <tr key={index}>
              {
                props.collumns.map((collumn, key) => (
                  <td key={key}>
                    { collumn.children &&
                      collumn.children(row[collumn.name], index, row)
                    }

                    { !collumn.children &&
                      <>
                        { (typeof row[collumn.name] === "number" && !collumn.boolean) &&
                          <>
                            { row[collumn.name] < 10 ?
                                `0${row[collumn.name]}`
                              :
                                row[collumn.name]
                            }
                          </>
                        }

                        { (typeof row[collumn.name] === "number" && collumn.boolean) &&
                          <>
                            {row[collumn.name] ? "Ativo" : "Inativo"}
                          </>
                        }

                        { typeof row[collumn.name] === "string" ? !collumn.copy ?
                          row[collumn.name]
                          : <TextField
                              value={`${row[collumn.name]}`}
                              hidden={false}
                              copy={true}
                              private={true}
                              minCarac={collumn.minCarac || 10}
                            /> : null
                        }

                        { typeof row[collumn.name] === "object" &&
                          row[collumn.name]
                        }
                      </>
                    }
                  </td>
                ))
              }    
              { props.onView &&
                <td>
                  <button 
                    onClick={() => props.onView(row)} 
                    className="view"
                  >
                    {props.viewLabel ? props.viewLabel : "Ver"}
                  </button>
                </td>
              }
              { (props.onEdit || props.onDel) &&
                <td>
                  { props.onEdit &&
                    <button className="edit" onClick={() => {
                      scrollToPosition(".DropdownArea__top .Arrow.active-arrow");
                      props.onEdit(row, index);
                    }}>Editar</button>
                  }
                  { props.onDel &&
                    <button className="del" onClick={() => props.onDel(row, index)}>Excluir</button>
                  }
                </td>
              }
            </tr>
          ))
        }
      </tbody>
    );
  }

  function renderData() {
    if ((props.collumns && props.collumns.length > 0) && (props.rows && props.rows.length > 0)) {
      return (
        <div className="TableArea__custom">
          {
            props.rows.map((row, index) => (
              <div className="TableArea__custom--item" key={index}>
                {
                  props.collumns.map((collumn, key) => (
                    <TextField 
                      minCarac={collumn.minCarac ? collumn.minCarac : null}
                      copy={collumn.copy ? collumn.copy : false}
                      label={`${collumn.label}:`} 
                      value={collumn.children ? collumn.children(row[collumn.name], index, row) : row[collumn.name]} 
                      key={key} 
                    />
                  ))
                }
                { props.onEdit &&
                  <button className="edit" onClick={() => props.onEdit(row, index)}>Editar</button>
                }
                { props.onDel &&
                  <button className="del" onClick={() => props.onDel(row, index)}>Excluir</button>
                }
                { props.onView &&
                  <button className="view" onClick={() => props.onView(row, index)}>Ver</button>
                }
              </div>
            ))
          }
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className={cn("TableArea", props.className)} id={props.id || ""}>
      <table className={`TableArea__table${props.onEditView ? " cursor-click" : ""}`}>
        {renderCollumns()}
        {renderRows()}
      </table>

      {renderData()}

      <div className="TableArea__pagination-container">
        { props.navigation &&
          <div className="TableArea__pagination">
            { (props.countPages > 1 && props.currentPage !== 1) &&
              <div 
                className="TableArea__pagination--button"
                onClick={() => props.currentPage < props.onPage(props.currentPage - 1)}
                style={{ paddingLeft: 2 }}
              >
                <BsChevronLeft />
              </div>
            }

            { props.countPages &&
              Array(props.countPages).fill(0).map((element, index) => {
                let paginationButtonClass = "TableArea__pagination--button";

                if ((index + 1) === props.currentPage) paginationButtonClass += " active";

                const render = (
                  <div 
                    onClick={() => props.onPage(index + 1)}
                    className={paginationButtonClass} 
                    key={index}
                  >
                    <p>{index + 1}</p>
                  </div>
                );
                
                if ((index + 1) === props.currentPage) {
                  return render;
                } else if ((index + 1) === (props.currentPage + 1)) {
                  return render;
                } else if ((index + 1) === (props.currentPage - 1)) {
                  return render;
                } else {
                  return false;
                }
              })
            }

            { (props.countPages > 1 && props.countPages !== props.currentPage) &&
              <div 
                className="TableArea__pagination--button"
                onClick={() => props.onPage(props.currentPage + 1)}
                style={{ paddingLeft: 2 }}
              >
                <BsChevronRight />
              </div>
            }
          </div>
        }
        {props.setPageQty && 
          <div className="TableArea__pagination-qty">
            Mostrar{" "}
            <select 
              value={props.pageQty} 
              onChange={(e)=>{
                props.setPageQty(e.target.value);
              }}
            > 
              <option value={3}>3</option>  
              <option value={5}>5</option>  
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={70}>70</option>
              <option value={100}>100</option>
            </select> 
            {" "}items por página
          </div>
        }
      </div>
    </div>
  );
}