// LIBs
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// CONTAINERs
import Layout from "../../components/Layout";

// VIEWs
import Home from "../../views/Home";
import Profile from "../../views/Profile";
import Account from "../../views/Account";
import Recover from "../../views/Recover";
import Groups from "../../views/Groups";
import Users from "../../views/Users";
import Webstores from "../../views/Webstores";
import Api from "../../views/Api";
import Menu from "../../views/Menu";
import Pages from "../../views/Pages";
import Financial from "../../views/Financial";
import Settings from "../../views/settings";
import Blocks from "../../views/Blocks";
import Content from "../../views/Content";
import Gallery from "../../views/Gallery";
import Contact from "../../views/Contact";
import MailMarketing from "../../views/MailMarketing";
import MailTags from "../../views/MailTags";
import NotFound from "../../views/NotFound";
import PlansPage from "../../views/Register";
import ComponentScreem from "../../views/ComponentScreen";

// STYLEs
import "react-toastify/dist/ReactToastify.css";

//COMPONENTs
import Loading from "../../components/Loading";

const Router = (props) => {
  const [loading, setLoading] = useState(true);

  function shownLoad() {
    setLoading(true);
  }

  function disabledLoad() {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }

  return (
    <BrowserRouter>
      {loading && <Loading />}
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/register-customer"
          element={
            <PlansPage onShownLoad={shownLoad} onHideLoad={disabledLoad} />
          }
        />
        <Route
          path="/account"
          element={<Account onHideLoad={disabledLoad} />}
        />
        <Route
          path="/account/recover/:token"
          element={
            <Recover onShownLoad={shownLoad} onHideLoad={disabledLoad} />
          }
        />
        <Route
          path="/profile"
          element={
            <Layout>
              <Profile onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/groups"
          element={
            <Layout>
              <Groups onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/users"
          element={
            <Layout>
              <Users onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/webstores"
          element={
            <Layout>
              <Webstores onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/api"
          element={
            <Layout>
              <Api onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/menu"
          element={
            <Layout>
              <Menu onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/componentscreem"
          element={
            <ComponentScreem
              onShownLoad={shownLoad}
              onHideLoad={disabledLoad}
            />
          }
        />
        <Route
          path="/pages"
          element={
            <Layout>
              <Pages onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/financial"
          element={
            <Layout>
              <Financial onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/settings"
          element={
            <Layout>
              <Settings onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/blocks"
          element={
            <Layout>
              <Blocks onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/content"
          element={
            <Layout>
              <Content onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/gallery"
          element={
            <Layout>
              <Gallery onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <Contact onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route
          path="/emailmarketing"
          element={
            <Layout>
              <MailMarketing
                onShownLoad={shownLoad}
                onHideLoad={disabledLoad}
              />
            </Layout>
          }
        />
        <Route
          path="/emailtags"
          element={
            <Layout>
              <MailTags onShownLoad={shownLoad} onHideLoad={disabledLoad} />
            </Layout>
          }
        />
        <Route path="*" element={<NotFound onHideLoad={disabledLoad} />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Router;
