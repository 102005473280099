//LIBs
import React from "react";

//COMPONENTs
import Button from "../../components/Button";

//ASSETs
import CheckedSquare from "../../assets/icons/check-square.svg";

const PlanCard = ({ plan, onClick, btnLabel, style, selected = false }) => {
  let currencyFormat = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  
  return (
    <div className="container-plan" style={style}>
      <div>
        <div className={`header ${selected && "selectedPlanCard"}`}>
          <p>{plan.name}</p>
        </div>
        <div className="content">
          <div className="prices">
            <p className={`value ${selected && "selectedPlanCard"}`}>{currencyFormat.format(plan.price)}</p>
            {plan.periods && <p className={`discount ${selected && "selectedPlanCard"}`}>por mês</p>}
          </div>
          <div className="list">
            {plan.json_items &&
              plan.json_items.map(
                (adv, index) =>
                  adv.is_active && (
                    <div
                      key={index}
                      className={`advantage ${index % 2 && "white"}`}
                    >
                      <div className="advantage-content">
                        <img src={CheckedSquare} alt="Icone de Check" />
                        <p>{adv.item}</p>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
      <div className="btn">
        <Button className={selected && "selectedPlanCard"} type="button" onClick={onClick}>
          {btnLabel}
        </Button>
      </div>
    </div>
  );
};

export default PlanCard;
