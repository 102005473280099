// LIBs
import React from "react";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__container">
        <p>© 2023 - Mdsystem Web Design LTDA</p>
      </div>
    </footer>
  );
}

export default Footer;