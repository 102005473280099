// LIBs
import React from "react";
import { Link } from "react-router-dom";

// COMPONENTs
import TextField from "../TextField";

// HELPERs
import cn from "../../core/helpers/cn";

export default (props) => (
  <div className={cn("InfoCard", props.className)}>
    <div className="InfoCard__container">
      { props.label &&
        <div className="InfoCard__container--title">
          <h3>{props.label}</h3>
        </div>
      }

      { (props.lines && props.lines.length > 0) &&
        <div className="InfoCard__container--lines">
          {
            props.lines.map((item, key) => (
              <TextField 
                label={item.label}
                value={item.value}
                key={key}
              />
            )) 
          }
        </div>
      }

      { props.to &&
        <div className="InfoCard__container--link">
          <Link to={props.to || ""}>Ver detalhes</Link>
        </div>
      }
    </div>
  </div>
)