// LIBs
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

// CONTAINERs
import Welcome from "../../containers/Account/Welcome";
import Login from "../../containers/Account/Login";
import Forgot from "../../containers/Account/Forgot";

// COMPONENTs
import LayoutWelcome from "../../components/LayoutWelcome";

const AccountView = (props) => {
  const [step, setStep] = useState("welcome");

  useEffect(()=>{props.onHideLoad()},[])

  const navigate = useNavigate();

  // Muda de Aba da págin
  function changeStep(event, step) {
    event.preventDefault();
    setStep(step);
  }

  return (
    <main className="AccountView">
      <LayoutWelcome setStep={setStep}>
        <div className="AccountView__content">
          <div className="AccountView__content--container">
            {step === "welcome" && (
              <Welcome
                onLogin={(event) => changeStep(event, "login")}
                onRegister={() => navigate('/register-customer')}
              />
            )}

            {step === "login" && (
              <Login
                onForgot={(event) => changeStep(event, "forgot")}
                onLogin={() => navigate('/register-customer')}
              />
            )}
            
            {step === "forgot" && <Forgot setStep={setStep} />}
          </div>
        </div>
      </LayoutWelcome>
    </main>
  );
};

export default AccountView;
