// LIBs
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// COMPONENTs
import Input from "../../components/Input";
import Button from "../../components/Button";

// CONFIG
import { getRecoverValidateToken, setRecover } from '../../core/api';

// HELPERs
import toast from "../../core/helpers/toast";

// ICONs
import { FiInstagram } from "react-icons/fi";
import { FaFacebook, FaLinkedin, FaWhatsapp } from "react-icons/fa";

// IMAGEs
import logo from "../../assets/account/logo_account.png";
import logoCompleted from "../../assets/account/logo_completed_account.png";

// STYLE
import "./styles.scss";

const ForgetView = (props) => {
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    props.onShownLoad()
    init();
  }, []);

  async function init() {
    let req = await getRecoverValidateToken(token);

    if (!token) {
      toast('error', 'Nenhum token encontrado');
    }
    if (!req.data.status) {
      toast('error', 'Token Invalido');

      setTimeout(() => {
        navigate('/');
      }, 3000);
    }

    props.onHideLoad()
  }

  async function handleClick() {
    if (!password || !password_confirmation) {
      toast('error', 'Preencha as Senhas');
      return;
    }

    if (password.length < 8 || password_confirmation.length < 8) {
      toast('error', 'Minimo 8 digitos');
      return;
    }

    if (password != password_confirmation) {
      toast('error', 'Senhas não confere');
      return;
    }

    let req = await getRecoverValidateToken(token);

    if (!req.data.status) {
      toast('error', 'Token Invalido');
      return;
    }

    let data = await setRecover(token, {
      password,
      password_confirmation
    });

    if (data.data.status) {
      toast('success', 'Senha alterada com sucesso');
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
  }

  // Muda de Aba da página
  function changeStep(event, step) {
    event.preventDefault();
  }

  return (
    <main className="AccountView AccountRecover">
      <div className="AccountView__header">
        <div className="AccountView__header--container">
          <a href="#">
            <img
              src={logo}
              alt=""
              height="100%"
              onClick={(event) => changeStep(event, "welcome")}
            />
          </a>
        </div>
      </div>

      <div className="AccountView__content AccountRecover__content">
        <div className="AccountView__content--container">
          <div className="WelcomeContainer">
            <div className="WelcomeContainer__content">
              <div className="WelcomeContainer__content--title">
                <h1>Recuperar Senha</h1>
              </div>

              <div className="WelcomeContainer__content--text">
                <p>Abaixo digite sua nova senha.</p>
              </div>

              <div className="WelcomeContainer__content--buttons AccountRecover__data">
                <Input
                  type="password"
                  label="Nova senha"
                  onChange={(e) => setPassword(e.target.value)} />
                <Input
                  type="password"
                  label="Repita a senha"
                  onChange={(e) => setPasswordConfirmation(e.target.value)} />
                <Button
                  type="button"
                  onClick={handleClick} children="Salvar" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="AccountView__footer">
        <div className="AccountView__footer--top">
          <div className="ContainerFooterTop">
            <div className="ContainerFooterTop__collumn">
              <img src={logoCompleted} alt="" />
            </div>

            <div className="ContainerFooterTop__collumn">
              <h3>Institucional:</h3>

              <div className="ContainerFooterTop__collumn--links">
                <a href="#">Home</a>
                <a href="#">Serviços</a>
                <a href="#">Sobre</a>
                <a href="#">Contato</a>
                <a href="#">Loja</a>
                <a href="#">Perguntas Frequentes</a>
              </div>
            </div>

            <div className="ContainerFooterTop__collumn">
              <h3>Contato:</h3>

              <div className="ContainerFooterTop__collumn--address">
                <p>(47) 99198-2664</p>
                <p>E-mail: contato@mdsystemweb.com.br</p>
                <p>Rua Francisco haag – Nº 600 – Bairro Rio Branco</p>
                <p>Brusque/SC</p>
              </div>
            </div>

            <div className="ContainerFooterTop__collumn">
              <h3>Redes Sociais:</h3>

              <div className="ContainerFooterTop__collumn--social">
                <a href="#"><FiInstagram /></a>
                <a href="#"><FaFacebook /></a>
                <a href="#"><FaLinkedin /></a>
                <a href="#"><FaWhatsapp /></a>
              </div>
            </div>
          </div>
        </div>

        <div className="AccountView__footer--bottom">
          <div className="ContainerBottomTop">
            <p>© 2023 - Mdsystem Web Design LTDA</p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ForgetView;