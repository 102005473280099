const config = {
    development: {
        endpoint: "https://api.developer.pageapi.mdsystemweb.com.br/api/",
        endpoint_auth: "https://api.developer.pageapi.mdsystemweb.com.br/api/v1/",
    },
    production: {
        endpoint: "https://api.developer.pageapi.mdsystemweb.com.br/api/",
        endpoint_auth: "https://api.developer.pageapi.mdsystemweb.com.br/api/v1/",
    }
};

export default config[process.env.NODE_ENV];