// LIBs
import React from "react";

// FORMs
import FormAccountForgot from "../../../Forms/Account/Forgot";

export default (props) => (
  <div className="ForgotContainer">
    <div className="ForgotContainer__content">
      <div className="ForgotContainer__content--title">
        <h1>Esqueceu a Senha?</h1>
      </div>

      <div className="ForgotContainer__content--text">
        <p>Preencha o campo corretamente</p>
      </div>

      <FormAccountForgot setStep={props.setStep} />
    </div>
  </div>
);