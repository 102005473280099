// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// CONTAINERs
import GenerateApi from "../../containers/Api/GenerateApi";

// HELPERs
import { validate } from "../../core/helpers/validate";

const ApiView = (props) => {
  const navigate = useNavigate();
  const [ activeId, setActiveId ] = useState("");
  const [ height, setHeight ] = useState(1);

  useEffect(() => {
    props.onShownLoad()
    validate(navigate);
  }, []);

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if(id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

      if (element !== null) {
        setHeight(element.clientHeight);
        setActiveId(id);
      }
    } else {
      setActiveId("");
      setHeight(1);
    }
  }

  return (
    <main className="ApiView">
      <DropdownArea
        label="Gerar Api" 
        id="generate-api" 
        activeId={activeId}
        height={height}
        onShow={isDropdownArea}
        className="ApiView__blocks"
      >
        <GenerateApi onHideLoad={props.onHideLoad}/>
      </DropdownArea>
    </main>
  );
}

export default ApiView;