// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// CONFIG
import { allConfig } from '../../core/api';

// ICONs
import { FiInstagram } from "react-icons/fi";
import { FaFacebook, FaLinkedin } from "react-icons/fa";

//ASSETs
import logoCompleted from "../../assets/account/logo_completed_account.png";

//COMPONENTs
import Loading from "../Loading";

// REDUCERs
import { allConfigApp } from "../../core/reducers/AppReducer/selectors";
import { setAllConfig } from "../../core/reducers/AppReducer/actions";


const LayoutWelcome = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState();
  const [configuration, setConfiguration] = useState([]);

  useEffect(() => {
    setLoading(true);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    setYear(currentYear);

    init();
  }, []);

  async function init() {
    let config = await allConfig();
    const decodedConfig = jwtDecode(config.token)
    props.setAllConfig(decodedConfig);

    if (decodedConfig.data && decodedConfig.data.logo) {
      // header
      document.querySelector('link[rel="icon"]').href = decodedConfig.data.favicon;
      document.querySelector('link[rel="apple-touch-icon"]').href = decodedConfig.data.favicon;
      document.querySelector('meta[property="og:image"]').content = decodedConfig.data.logo;
      document.querySelector('title').text = decodedConfig.data.fantasy_name;
      document.querySelector('meta[name="author"]').content = decodedConfig.data.fantasy_name;
      setLoading(false);
    }
    
  }

  const toCaptalizeString = (string) =>{
    if(typeof string === "string"){
      return string.split(" ").map(str=>str.charAt(0)+str.toLowerCase().substring(1)).join(" ")
    }
  }

  return  (
    <>
    {loading ?( 
      <Loading />
    ):(
    <>
      <div className="AccountView__header">
        <div className="AccountView__header--container">
          <a href="#">
            <img
              src={props.allConfig.logo ?? ""}
              alt=""
              height="100%"
              onClick={() => props.setStep ? props.setStep('welcome') : navigate("/account")}
            />
          </a>
        </div>
      </div>
      {props.children}
      <div className="AccountView__footer">
        <div className="AccountView__footer--top">
          <div className="ContainerFooterTop">
            <div className="ContainerFooterTop__collumn">
              <img src={logoCompleted ?? ""} alt="" />
            </div>

            <div className="ContainerFooterTop__collumn">
              <h3>Institucional:</h3>

              <div className="ContainerFooterTop__collumn--links">
                <a href="https://www.mdsystemweb.com.br/" target="_blank">Home</a>
                <a href="https://www.mdsystemweb.com.br#services" target="_blank">Serviços</a>
                <a href="https://www.mdsystemweb.com.br#about" target="_blank">Sobre</a>
                <a href="https://www.mdsystemweb.com.br#contact" target="_blank">Contato</a>
                <a href="https://www.mdsystemweb.com.br#hilights" target="_blank">Loja</a>
                <a href="https://www.mdsystemweb.com.br/faq" target="_blank">Perguntas Frequentes</a>
              </div>
            </div>

            <div className="ContainerFooterTop__collumn">
              <h3>Contato:</h3>

              <div className="ContainerFooterTop__collumn--address">
                <p>{props.allConfig.phone ?? ""}</p>
                <p>E-mail: {props.allConfig.email ?? ""}</p>
                <p>{toCaptalizeString(props.allConfig.street_name) ?? ""} – Nº {props.allConfig.street_number ?? ""} – {toCaptalizeString(configuration.neighborhood) ?? ""}</p>
                <p>{toCaptalizeString(props.allConfig.city) ?? ""}/{props.allConfig.uf ?? ""}</p>
              </div>
            </div>

            <div className="ContainerFooterTop__collumn">
              <h3>Redes Sociais:</h3>

              <div className="ContainerFooterTop__collumn--social">
                <a href={props.allConfig.facebook ?? ""}><FiInstagram /></a>
                <a href={props.allConfig.instagram ?? ""}><FaFacebook /></a>
                <a href={props.allConfig.linkedin ?? ""}><FaLinkedin /></a>
              </div>
            </div>
          </div>
        </div>

        <div className="AccountView__footer--bottom">
          <div className="ContainerBottomTop">
            <p>© {year} - Mdsystem Web Design LTDA</p>
          </div>
        </div>
      </div>
      </>
    )}
  </>
  );
}

const mapStateToProps = (state) => ({
  allConfig: allConfigApp(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setAllConfig,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWelcome);