// LIBs
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import multi from "redux-multi";
import promise from "redux-promise";
import thunk from "redux-thunk";

// REDUCERs
import { reducers } from "../reducers";

const store = applyMiddleware(thunk, promise, multi)(createStore)(reducers);

export default store;
