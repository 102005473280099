// LIBs
import React from "react";

// FORMs
import FormSettingsPJ from "../../../Forms/Settings/PJ";

export default props => (
  <div className="ContainerPJ">
    <FormSettingsPJ data={props.data} onLoad={props.onRequest} />
  </div>
);