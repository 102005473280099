// LIBs
import React from "react";

// COMPONENTs
import InfoCard from "../../../components/InfoCard";

export default (props) => (
  <div className="Details">
    {/* <InfoCard 
      className="Details__item"
      label="Financeiro"
      lines={[
        { label: "Fatura:", value: "0012883" },
        { label: "Vencimento:", value: "12/01/2023" },
        { label: "Valor:", value: "R$39,00" },
        { label: "Tipo:", value: "Mensal" },
      ]}
      to="/financial"
    /> */}
    <InfoCard
      className="Details__item"
      label="Usúarios"
      lines={[
        { label: "Quantidade:", value: props.data.users ? props.data.users.count : '' },
        { label: "Ativos:", value: props.data.users ? props.data.users.active : '' },
        { label: "Inativos:", value: props.data.users ? props.data.users.inactive : '' }

      ]}
    />
    <InfoCard
      className="Details__item"
      label="Blocos"
      lines={[
        { label: "Quantidade:", value: props.data.blocks ? props.data.blocks.count : '' },
        { label: "Ativos:", value: props.data.blocks ? props.data.blocks.active : '' },
        { label: "Inativos:", value: props.data.blocks ? props.data.blocks.inactive : '' },
      ]}
    />
    <InfoCard
      className="Details__item"
      label="Menus"
      lines={[
        { label: "Quantidade:", value: props.data.menus ? props.data.menus.count : '' },
        { label: "Ativos:", value: props.data.menus ? props.data.menus.active : '' },
        { label: "Inativos:", value: props.data.menus ? props.data.menus.inactive : '' },
      ]}
    />
    {/* <InfoCard
      className="Details__item"
      label="Páginas"
      lines={[
        { label: "Quantidade:", value: 15 },
        { label: "Ativos:", value: 12 },
        { label: "Inativos:", value: 3 },
      ]}
    /> */}
  </div>
);