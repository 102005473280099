// LIBs
import React, { useState, useEffect } from "react";

// API
import { updatePJ } from "../../../core/api";

// SERVICEs
import toast from "../../../core/helpers/toast";

// COMPONENTs
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const FormSettingsPJ = (props) => {
  const [ corporateName, setCorporateName ] = useState("");
  const [ fantasyName, setFantasyName ] = useState("");
  const [ stateTax, setStateTax ] = useState("");
  const [ cnpj, setCnpj ] = useState("");

  useEffect(() => {
    if (props.data) {
      const data = props.data;

      setCorporateName(data.corporate_name);
      setFantasyName(data.fantasy_name);
      setStateTax(data.state_tax || "");
      setCnpj(data.document);
    }
  }, [props.data])

  async function sendUpdate() {
    if (corporateName && fantasyName && cnpj) {
      const params = {
        corporate_name: corporateName,
        fantasy_name: fantasyName,
        state_tax: stateTax,
        document: cnpj,
      };

      const req = await updatePJ(params);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data) {
        props.onLoad();
        toast("success", "Registrado com sucesso");
      } else {
        toast("error", "Houve um erro ao tentar atualizar");
      }
    } else {
      toast("warning", "Preencha os campos corretamente!");
    }
  }

  return (
    <div className="FormSettingsPJ">
      <div className="FormSettingsPJ__inputs">
        <div className="FormSettingsPJ__inputs--duo">
          <Input 
            label="Nome da Corporação: *" 
            className="InputDuo"
            value={corporateName} 
            onChange={(e) => setCorporateName(e.target.value)}
          />

          <Input 
            label="Nome Fantasia: *" 
            className="InputDuo" 
            value={fantasyName}
            onChange={(e) => setFantasyName(e.target.value)}
          />
        </div>

        <div className="FormSettingsPJ__inputs--duo">
          <Input 
            libInput="mask"
            type="text"
            label="CNPJ: *" 
            className="InputDuo" 
            mask="99.999.999/9999-99"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
          />
          <Input 
            label="Imposto Estadual:" 
            className="InputDuo" 
            value={stateTax}
            onChange={(e) => setStateTax(e.target.value)}
          />
        </div>
      </div>

      <div className="FormSettingsPJ__button">
        <Button type="button" onClick={sendUpdate}>{props.data ? "Editar" : "Salvar"}</Button>
      </div>
    </div>
  );
};

export default FormSettingsPJ;