// LIBs
import React from "react";

// FORMs
import FormAccountLogin from "../../../Forms/Account/Login";

export default props => (
  <div className="LoginContainer">
    <div className="LoginContainer__content">
      <div className="LoginContainer__content--title">
        <h1>Faça seu login</h1>
      </div>

      <FormAccountLogin className="LoginContainer__content--form" />

      <div className="LoginContainer__content--forgot">
        <p>Você se esqueceu da senha? <span onClick={props.onForgot}>Clique Aqui!</span></p>
      </div>

      <div className="LoginContainer__content--register">
        <p>Você não tem conta? <span onClick={props.onLogin}>Clique Aqui!</span></p>
      </div>
    </div>
  </div>
);