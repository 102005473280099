// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// CONFIG
import { 
  getWebStores, 
  getMenus, 
  registerMenus, 
  editMenus, 
  deleteMenus 
} from "../../core/api";

// HELPERs
import toast from "../../core/helpers/toast";
import { validate } from "../../core/helpers/validate";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// CONTAINERs
import Menu from "../../containers/Menu/Menu";

// REDUCERs
import { listWebstore } from "../../core/reducers/WebstoreReducer/selectors";

const MenuView = (props) => {
  const navigate = useNavigate();
  const [ activeId, setActiveId ] = useState("");
  const [ height, setHeight ] = useState(1);
  const [ menus, setMenus ] = useState([]);
  const [ menu, setMenu ] = useState({});
  const [ pagePerView, setPagePerView ] = useState(3);
  const [ orderTableBy, setOrderTableBy ] = useState("id");
  const [ webstore, setWebstore ] = useState(0);
  const [ order, setOrder ] = useState("asc");

  useEffect(() => {
    const webstoreValue = +props.webstore.value
    setWebstore(webstoreValue)
    allWebStoresAndMenu(webstoreValue);
  }, [pagePerView, orderTableBy, order, props.webstore.value])

  useEffect(()=>{
    validate(navigate);
    props.onShownLoad()
  },[])

  useEffect(() => {
    toggleDropdown(activeId);
  }, [menus])

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if(id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

      if (element !== null) {
        setHeight(element.clientHeight);
        setActiveId(id);
      }
    } else {
      setActiveId("");
      setHeight(1);
    }
  }

  function switchOrder (){
    setOrder(prev=> prev === "asc" ? "desc" : "asc")
  }

  async function allWebStoresAndMenu(webstoreValue) {
    if (props.list) {
      allMenus(1, webstoreValue, props.list);
    }
    props.onHideLoad()
  }

  async function allMenus(page = 1, selectedWebstore = webstore, webstoreList = props.list) {
    const req = await getMenus({
      page:page, 
      limit:pagePerView, 
      orderby: orderTableBy, 
      order:order,
      webstore_id:selectedWebstore
    });

    if (req.error && req.error.title) {
      toast("error", req.error.title);
    }

    if (req.data) {
      const menusWithWebstores = req.data.list.map(item=>{
        return{...item, webstore_id: webstoreList.find(webstore=> webstore.id === item.webstore_id).name}})
      setMenus({...req.data, list:menusWithWebstores});
    }
  }

  function sendForm(id, title, webstore, link, menu) {
    if (id) {
      editMenu(id, title, webstore, link, menu);
    } else {
      addMenu(title, webstore, link, menu);
    }
  }

  async function addMenu(title, webstore, link, menu) {
    if (title && (webstore !== "" && webstore !== "null") && link) {
      let params = {};

      params.title = title;
      params.webstore_id = webstore;
      params.link = link;

      if (menu !== "" && menu !== "null") {
        params.menu_id = menu;
      }      

      const req = await registerMenus(params);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.title) {
        toast("success", req.data.title);
      }

      await allMenus();
    } else {
      toast("warning", "Preencha todas as informações corretamente!");
    }
  }

  async function editMenu(id, title, webstore, link, menu) {
    if (id && title && (webstore !== "" && webstore !== "null") && link) {
      let params = {};

      params.title = title;
      params.webstore_id = webstore;
      params.link = link;

      if (menu !== "" && menu !== "null") {
        params.menu_id = menu;
      }

      const req = await editMenus(id, params);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.title) {
        toast("success", req.data.title);
      }

      await allMenus();
    }
  }

  async function delMenu(menuItem) {
    if (menuItem.id) {
      const req = await deleteMenus(menuItem.id);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.title) {
        toast("success", req.data.title);
      }

      await allMenus();
    }
  }

  return (
    <main className="MenuView">
      <DropdownArea
        label="Menus" 
        id="menu" 
        activeId={activeId}
        height={height}
        onShow={isDropdownArea}
        className="MenuView__blocks"
      >
        <Menu 
          setPageQty={(e)=>setPagePerView(+e)}
          webStores={props.list} 
          menus={menus} 
          menu={menu}
          setMenu={setMenu}
          onChange={item => setMenu(item)}
          onDel={item => delMenu(item)}
          onSend={sendForm}
          onPage={(page)=>allMenus(page)}
          onFilter={(filter)=>{
            if (filter === orderTableBy) {
              switchOrder();
            } else {
              setOrder("asc");
              setOrderTableBy(filter);
            }
          }}
          filter={orderTableBy}
          order={order}
        />
      </DropdownArea>
    </main>
  );
}

const mapStateToProps = state => ({
  webstore:state.webstore,
  list: listWebstore(state),
});

export default connect(mapStateToProps)(MenuView);
