// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// CONFIG
import { createAccount } from '../../../core/api';

// COMPONENTs
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";

// HELPERs
import toast from "../../../core/helpers/toast";

const FormAccountRegister = (props) => {
  const [ type, setType ] = useState(1);
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ acceptTerms, setAcceptTerms ] = useState(false);
  const [ reCaptcha, setReCaptcha ] = useState({x: 0, y: 0, result: 0});

  const navigate = useNavigate();

  async function handleClick() {
    eventToast(firstName, "Preencha o Primeiro Nome");
    eventToast(lastName, "Preencha o Ultimo Nome");
    eventToast(email, "Preencha o E-mail");
    eventToast(password, "Preencha Senha");
    
    if (firstName && lastName && email && password) {
      if (password.length < 8) {
        toast.error("Senha com no minimo 08 caracteres", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; 
      }

      if(!acceptTerms){
        toast.error("Aceite os termos e políticas de privacidade", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      let params = {
        ...props.selectedPlan,
        type: type,
        firstname: firstName,
        lastname: lastName,
        email: email,
        password: password,
        initval: reCaptcha.x,
        endval:reCaptcha.y,
        resultval:reCaptcha.result
      }

      let sum = (reCaptcha.x + reCaptcha.y);

      if (sum !== reCaptcha.result) {
        toast("error", "reCaptcha invalid!");
        return;
      }

      let req = await createAccount(params);

      if (req.data.token) {
        toast("success", "Registrado com sucesso");
        
        setTimeout(() => {
          window.localStorage.setItem('token', req.data.token);
          navigate('/');
        }, 3000); 
      }

      if (req.error.title) {
        let message = '';
        if (req.error.title.email) {
          message = req.error.title.email[0];
        } else {
          message = req.error.title;
        }
        
        toast("error", message);
      }
    }
  }

  function eventToast(value, text) {
    if(!value) {
      toast.error(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }
  }

  useEffect(() => {
    let newReCaptcha = { ...reCaptcha };
    newReCaptcha.x = Math.floor(Math.random() * 20);
    newReCaptcha.y = Math.floor(Math.random() * 20);
    setReCaptcha(newReCaptcha);
  }, [])

  function changeReCaptcha(value) {
    let newReCaptcha = { ...reCaptcha };
    newReCaptcha.result = +value;
    setReCaptcha(newReCaptcha);
  }
  
  return (
    <div className="FormAccountRegister">
      <div className="FormAccountRegister__inputs">
        <Select
          label="Tipo de usuário"
          className="type"
          onChange={(e) => setType(e.target.value)}
        >
          <option value={1}>Fisica</option>
          <option value={0}>Juridica</option>
        </Select>
        <div className="FormAccountRegister__inputs--duo">
          <Input 
            label="Nome:" 
            type="text" 
            className="InputDuo" 
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input 
            label="Sobrenome:" 
            type="text" 
            className="InputDuo" 
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <Input 
          label="E-mail:" 
          type="email" 
          className="FormAccountRegister__inputs--control" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input 
          label="Senha:" 
          type="password" 
          className="FormAccountRegister__inputs--control" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input 
          label={`${reCaptcha.x} + ${reCaptcha.y} =`} 
          type="number" 
          className="FormAccountRegister__inputs--control" 
          value={reCaptcha.result}
          onChange={(e) => changeReCaptcha(e.target.value)}
        />
        <div className="FormAccountRegister__terms-accept">
          <input 
            checked={acceptTerms} 
            onChange={()=>setAcceptTerms(prev=>!prev)} 
            type="checkbox" 
            name="accept-terms" 
            id="accept-terms" 
          />
          <p>Aceito os {" "}
            <a 
              href="https://www.mdsystemweb.com.br/privacy-policy" 
              target="_blank"
              rel="noreferrer"
            >Termos de Uso e Política de Privacidade</a>
          </p>
        </div>
      </div>


      <div className="FormAccountRegister__change-page">
        <div>
          <p>Deseja alterar de plano? <span onClick={props.onChangePlan}>Clique Aqui!</span></p>
        </div>
        <div>
          <p>Já possui uma conta? <span onClick={props.onLogin}>Clique Aqui!</span></p>
        </div>
      </div>


      <div className="FormAccountRegister__button">
        <Button type="button" onClick={handleClick}>Cadastrar</Button>
      </div>
    </div>
  );
}

export default FormAccountRegister;