// LIBs
import React from "react";

// FORMs
import FormAccountRegister from "../../../Forms/Account/Register";

export default props => (
  <div className="RegisterContainer">
    <div className="RegisterContainer__content">
      <div className="RegisterContainer__content--title">
        <h1>Novo Cadastro</h1>
      </div>

      <FormAccountRegister onChangePlan={props.onChangePlan} onLogin={props.onLogin} selectedPlan={props.selectedPlan} />
    </div>
  </div>
);