// LIBs
import React, { useState, useEffect } from "react";

// CONFIG
import { getGroups, getUser, getUserUpdate } from '../../../core/api';

// HELPERs
import toast from "../../../core/helpers/toast";

// COMPONENTs
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Grid from "../../../components/Grid";

import "./styles.scss";

const FormUserProfile = (props) => {
  const [ firstname, setFirstname] = useState('');
  const [ lastname, setLastNname ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ group_id, setGroupId ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ rg, setRG ] = useState('');
  const [ cpf, setCPF ] = useState('');

  const [ status, setStatus ] = useState('');
  const [ groups, setGroups ] = useState([]);

  useEffect(() => {
    init();
  }, [props.header]);

  /**
   * function init in page
   */
  async function init() {
    if (props.header) {
      let req = await getUser();
      
      if(req.data.firstname) {
        setFirstname(req.data.firstname);
        setLastNname(req.data.lastname);
        setPhone(req.data.phone || "");
        setRG(req.data.rg || "");
        setCPF(req.data.cpf || ""); 

        setStatus(req.data.is_active);
        setGroupId(req.data.group_id);
      } 

      let list = await getGroups(props.header);
      setGroups(list.data); 
    }
  }

  /**
   * event click button save
   */
  async function handleClick() {
    eventToast(firstname, "Preencha o Nome");
    eventToast(lastname, "Preencha o Sobrenome");
    eventToast(phone, "Preencha o Telefone");
    eventToast(rg, "Preencha o RG");
    eventToast(cpf, "Preencha o CPF");

    let params = {
      firstname:firstname,
      lastname:lastname,
      password:password,
      group_id:group_id,
      phone:phone,
      rg:rg,
      cpf:cpf
    }

    if (firstname && lastname && phone && rg && cpf) {
      let req = await getUserUpdate(params);
      
      if (req.data.title) {
        toast("success", req.data.title);
      }

      props.onRequest();
    }
  }
  function eventToast(value, text) {
    if(!value) {
      toast("error", text);
      return;
    }
  }

  return (
    <div className="FormUserUser FormUserProfile">
      <div className="FormUserUser__inputs">
        <Grid>
          <Input 
            type="text"
            label="Nome: *" 
            className="InputDuo" 
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <Input 
            type="text"
            label="Sobrenome: *" 
            className="InputDuo" 
            value={lastname}
            onChange={(e) => setLastNname(e.target.value)}
          />
          <Input 
            type="text"
            libInput="mask"
            mask="(99) 9 9999-9999"
            label="Telefone: *" 
            className="InputDuo" 
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Input 
            type="text"
            label="RG: *" 
            className="InputDuo" 
            value={rg}
            onChange={(e) => setRG(e.target.value)}
          />
          <Input 
            type="text"
            libInput="mask"
            mask="999.999.999-99"
            label="CPF: *" 
            className="InputDuo" 
            value={cpf}
            onChange={(e) => setCPF(e.target.value)}
          />
          <Input 
            type="password"
            label="Nova Senha:" 
            className="InputDuo" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
      </div>

      <div className="FormUserUser__button">
        <Button type="button" onClick={handleClick}>Salvar</Button>
      </div>
    </div>
  );
}

export default FormUserProfile;