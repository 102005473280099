// LIBs
import React from "react";

// FORMs
import FormWebstores from "../../Forms/Webstores";


export default props => (
  <div className="Users">
    <FormWebstores showForm={props.showForm} onLoad={props.onRequest} user={props.user} />
  </div>
);