// LIBs
import { encode, decode } from "js-base64";

// REDUCERs
import * as types from "./types";

const webstoresName = "@pageapi:webstores";

const INITIAL_STATE = {
  value:"0",
  list: [],
};

function WebstoreReducer(state = INITIAL_STATE, action) {
  let newState = { ...state };

  const webstores = localStorage.getItem(webstoresName);
  if (webstores) {
    newState.list = JSON.parse(decode(webstores));
  }
  
  switch(action.type) {
    case "webstore/change":
      return {...newState , value:action.payload};
    case types.CHANGE_LIST_WEBSTORE:
      localStorage.setItem(webstoresName, encode(JSON.stringify(action.payload.data)));
      console.log(action.payload.data);
      return { ...newState, list: action.payload.data };
    default:
      return newState;
  }
}

export default WebstoreReducer;