// LIBs
import React from "react";

// COMPONENTs
import Table from "../../../components/Table";

// FORMs
import FormMenuMenu from "../../../Forms/Menu/Menu";

const collumns = [
  { label: "ID", name: "id" },
  { label: "Titulo", name: "title" },
  { label: "Link", name: "link" },
  { label: "Webstore", name: "webstore_id"}
];

export default (props) => (
  <div className="Menu">
    <FormMenuMenu 
      data={props.menu}
      webStores={props.webStores} 
      menus={props.menus.list}
      onSend={props.onSend}
      setMenu={props.setMenu}
    >
      <Table 
        onFilter={props.onFilter}
        navigation={true}
        setPageQty={props.setPageQty}
        countPages={props.menus.count_pages}
        currentPage={props.menus.current_page}
        collumns={collumns}
        rows={props.menus.list}
        onPage={props.onPage}
        onEdit={(row) => props.onChange(row)}
        onDel={(row) => props.onDel(row)}
        filter={props.filter}
        order={props.order}
      />
    </FormMenuMenu>
  </div>
);