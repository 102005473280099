// LIBs
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// CONFIG
import { getCancelStatus, getWebStoresAll, logOut } from '../../../core/api';

// COMPONENTs
import TextField from "../../../components/TextField";
import UpgradeButton from "../../../components/UpgradeButton";
import Select from "../../../components/Select"
import CancelSignature from "../../../components/CancelSignature";

// ICONs
import { BsThreeDotsVertical } from "react-icons/bs";

// IMAGEs
import logoCompleted from "../../../assets/layout/logo_completed_layout.png";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// SELECTORs
import { allUser } from "../../../core/reducers/UserReducer/selectors";
import { valueWebstore, listWebstore } from "../../../core/reducers/WebstoreReducer/selectors";

//HELPER
import toast from "../../../core/helpers/toast";

const BarInfo = (props) => {
  const [showToolBox, setShowToolBox] = useState(false);
  const [webstores, setWebstores] = useState([]);
  const [selectedWebstore, setSelectedWebstore] = useState(0);
  const [validate, setValidate] = useState();
  const [cancelStatus, setCancelStatus] = useState();
  
  function hideToolBox(e) {
    if (e.target.classList.contains("active-box")) {
      setShowToolBox(false);
    }
  }

  useEffect(() => {
    fetchCancel();
    selectCachedWebstore()
  }, [])

  useEffect(() => {
    fetchWebstores();
  }, [props.value, props.list])

  async function fetchWebstores() {
    if(webstores !== props.list) setWebstores(props.list);
    
    let date = new Date(props.user.validate).toLocaleDateString("pt-BR", {timeZone:"UTC"});
    
    if(validate !== date) setValidate(date);
    
  }

  function selectCachedWebstore() {
    const webstoresIds = props.list.map(ws=>ws.id)
    const cachedWebstore = localStorage.getItem("selectedWebstore")
    
    if (cachedWebstore === null || !webstoresIds.includes(+cachedWebstore)) {
      localStorage.setItem("selectedWebstore", webstoresIds[0])
      setSelectedWebstore(webstoresIds[0]);
      props.changeWebstore(webstoresIds[0]);
    }else {
      setSelectedWebstore(cachedWebstore);
      props.changeWebstore(cachedWebstore);
    }
  }

  async function fetchCancel() {
    const status = await getCancelStatus();
    if (status.error.title) {
        toast("error", status.error.title);
        return;
    }

    setCancelStatus(status.data);
  }

  function selectWebstore(e){
    localStorage.setItem("selectedWebstore", e.target.value)
    setSelectedWebstore(e.target.value)
    props.changeWebstore(e.target.value)
  }

  // logout
  async function logout() {
    await logOut(props.header);
    window.localStorage.removeItem('token');
  }

  const lastNameSplited = (props.user && props.user.lastname) ? props.user.lastname.split(" ") : "";

  return (
    <div className="BarInfo">
      <div className="BarInfo__container">
        <div className="Info">
         {props.user.user_id !== null && <div className="Info__tokens">
            {webstores.map((ws, index)=>{
              return index < 2 && (
              <TextField
                key={index}
                label={`${ws.name}:`}
                value={`${ws.token}`}
                minCarac={8}
                copy={true}
              />
            )})}
            { webstores.length > 2 && (
              <a href="/webstores" >Ver Mais</a>
            )}
          </div>}

            {props.user.user_id === null && (!cancelStatus ?
              <div className="Info__tokens">
                <TextField
                  label={`Plano:`}
                  value={`${props.user.plan ? props.user.plan.name + " | " + validate : "Nenhum plano ativo"}`}
                />
                <UpgradeButton/>
              </div>
            : <CancelSignature type="status"/>) }


            {/* if user type pj and user is master*/}
            {props.user.user_id === null && !props.type &&
              <div className="Info__social">
                <h2>Razão Social</h2>
                <p>MdsystemWeb...</p>
              </div>
            }
          </div>

        <div className="Options">
          <div className="Options__logo">
            <Link to="/"><img src={logoCompleted} height="100%" alt="" /></Link>
          </div>

          <div className="Options__Select">
          {webstores &&
            <Select value={selectedWebstore} onChange={selectWebstore}>
              {webstores.map((ws)=>(
                <option value={ws.id} key={ws.id}>{ws.name}</option>
              ))}
            </Select>
            }
            <div className="Options__image">
              <p>
                { lastNameSplited !== "" 
                    ? props.user.firstname[0].toUpperCase()+lastNameSplited[lastNameSplited.length - 1][0].toUpperCase()
                    : "MW"
                }
              </p>
            </div>
          <div className="Options__button" onMouseLeave={() => setShowToolBox(false)}>
            <button onClick={() => setShowToolBox(!showToolBox)}><BsThreeDotsVertical /></button>

            <div
              className={`Options__button--box${showToolBox ? " active-box" : ""}`}
              onClick={hideToolBox}
            >
              <div className="ToolBox">
                <div className="ToolBox__top">
                  <h3>Olá, {props.firstName}!</h3>
                </div>

                <div className="ToolBox__bottom">
                  <Link to="/profile">Minha Conta</Link>
                  <Link to="/account" onClick={logout}>Sair</Link>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators({
 changeWebstore: (payload)=>{return{type:"webstore/change", payload:payload}}
}, dispatch);

const mapStateToProps = (state) => ({ 
  user: allUser(state),
  value: valueWebstore(state),
  list: listWebstore(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarInfo);