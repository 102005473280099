// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// CONTAINERs
import Header from "../../containers/Layout/Header";
import BarInfo from "../../containers/Layout/BarInfo";
import Footer from "../../containers/Layout/Footer";

// SELECTORs
import { idUser, firstNameUser, typeUser, tokenUser } from "../../core/reducers/UserReducer/selectors";

const Layout = (props) => {
  const navigate = useNavigate();
  
  const [ showMore, setShowMore ] = useState(false);

  useEffect(() => {
    if(!window.localStorage.getItem('token')) {
      navigate('/account');
    }
  }, []);

  if (props.id) {
    return (
      <div className={`LayoutGrid${showMore ? " active-grid" : ""}`}>
        <Header showMore={showMore} setShowMore={setShowMore} />
        <BarInfo 
          firstName={props.firstName} 
          token={props.token} 
          type={props.type} 
        />
          <div className="LayoutGrid__container">{props.children}</div>
        <Footer />
      </div>
    );
  } else {
    return false;
  }
}

const mapStateToProps = state => ({
  id: idUser(state),
  firstName: firstNameUser(state),
  type: typeUser(state),
  token: tokenUser(state),
});

export default connect(mapStateToProps)(Layout);