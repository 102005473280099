// COMPONENTs
import Table from "../../../components/Table";

export default props => {
  const collumns = [
    { label: "Nome", name: "name" },
    { label: "Ações", name: "name", children: renderActions },
  ];

  function renderActions(name, index, row) {
    return (
      <div className="TdActions">
        { props.tag &&
          <button 
            style={{
              color: props.tag.contact.indexOf(name) > -1 ? "#F00" : "#00A1E1"
            }}
          >
            { props.tag.contact.indexOf(name) > -1 ? "Remover" : "Adicionar" }
          </button>
        }
      </div>
    )
  }

  return (
    <div className="MailContacts">
      <Table 
        collumns={collumns}
        rows={props.data}
      />
    </div>
  )
}