// LIBs
import React, { useState, useEffect } from "react";

// COMPONENTs
import Table from "../../../components/Table";

const collumns = [
  { label: "Fatura", name: "mp_id" },
  { label: "Data do Pagamento", name: "created" },
  { label: "Valor Total", name: "amount" },
  { label: "Plano", name: "plan" },
  { label: "Tipo", name: "type" },
];

const collumnsResult = [
  { label: "Descrição", name: "description" },
  { label: "Valor", name: "value" },
];

export default (props) => {
  const [order, setOrder] = useState();
  const [rowsResult, setRowsResult] = useState([]);

  useEffect(() => {
    if (order) {
      let params = [
        { description: `${order.plan} | ${order.mp_id}`, value: order.amount },
        { description: "Qt WebStore", value: order.webstores_quantity },
        { description: "Sub Total", value: order.amount },
        { description: "Total", value: order.amount },
        { description: "", value: order.created },
      ];
      setRowsResult(params);
    }
  }, [order]);

  return (
    <div className="Paid">
      <Table
        collumns={collumns}
        rows={props.data}
        className="Paid__invoices"
        onView={(row) => setOrder(row)}
      />
      {order &&
        <Table
          id="table-paid-financial"
          collumns={collumnsResult}
          rows={rowsResult}
          className="Paid__results"
          hiddeNavigation={true}
        />
      }
    </div>
  );
}