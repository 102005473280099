// LIBs
import React from "react";

// FORMs
import FormApiGenerateApi from "../../../Forms/Api/GenerateApi";

export default (props) => (
  <div className="GenerateApi">
    <FormApiGenerateApi onHideLoad={props.onHideLoad} />
  </div>
);