// LIBs
import React, { useState } from "react";

// API
import { addUsersMail } from "../../../core/api";

// SERVICEs
import toast from "../../../core/helpers/toast";

// COMPONENTs
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const FormSettingsRegister = (props) => {
  const [host, setHost] = useState("")
  const [port, setPort] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [encypt, setEncypt] = useState("")

  async function sendMail() {
    if (host && port && email && password && encypt) {
      const params = {
        mail_host: host,
        mail_port: port,
        mail_username: email,
        mail_password: password,
        mail_encryption: encypt
      };

      const req = await addUsersMail(params);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data) {
        toast("success", "Registrado com sucesso");
      } else {
        toast("error", "Houve um erro ao tentar atualizar");
      }
    } else {
      toast("warning", "Preencha os campos corretamente!");
    }
  }

  return (
    <div className="FormSettingsRegister">
      <div className="FormSettingsRegister__inputs">
        <div className="FormSettingsRegister__inputs--duo">
          <Input 
            label="Host: *" 
            className="InputDuo"
            value={host} 
            onChange={(e) => setHost(e.target.value)}
          />

          <Input 
            label="Porta: *" 
            className="InputDuo" 
            value={port}
            onChange={(e) => setPort(e.target.value)}
          />
        </div>

        <div className="FormSettingsRegister__inputs--duo">
          <Input 
            type="text"
            label="E-mail: *" 
            className="InputDuo"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input 
            label="Senha: *" 
            className="InputDuo" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <Input 
            label="Criptografia: *"
            value={encypt}
            onChange={(e) => setEncypt(e.target.value)}
          />
      </div>

      <div className="FormSettingsRegister__button">
        <Button type="button" onClick={sendMail}>Salvar</Button>
      </div>
    </div>
  );
};

export default FormSettingsRegister;