// LIBs
import React, { useState, useEffect } from "react";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";
import BlockNav from "../../components/BlockNav";

// CONTAINERs
import Paid from "../../containers/Financial/Paid";
import Pending from "../../containers/Financial/Pending";
import Canceled from "../../containers/Financial/Canceled";

// HELPERs
import scrollToPosition from "../../core/helpers/scrollToTop";

// CONFIG
import { getOrders } from '../../core/api';

const INITIAL_TABLE = {
  pending: false,
  paid: false,
  canceled: false,
}

const FinancialView = (props) => {
  const [ activeId, setActiveId ] = useState("");
  const [ height, setHeight ] = useState(1);
  const [ tableResults, setTableResults ] = useState(INITIAL_TABLE);

  const [ approved, setApproved ] = useState([]);
  const [ pending, setPending ] = useState([]);
  const [ cancelled, setCancelled ] = useState([]);

  useEffect(() => {
    props.onShownLoad()
    list();
  }, []);
  /**
   * list orders
   */
  async function list() {
    let req_ap = await getOrders("approved");
    let req_pen = await getOrders("pending");
    let req_can = await getOrders("cancelled,rejected");

    setApproved(req_ap.data);
    setPending(req_pen.data);
    setCancelled(req_can.data);

    props.onHideLoad()
  }

  useEffect(() => {
    if (activeId !== "") {
      setHeight(resize(activeId));
    }
  }, [tableResults])

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
        setTableResults(INITIAL_TABLE);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if(id !== "") {
      setHeight(resize(id));
      setActiveId(id);
    } else {
      setActiveId("");
      setHeight(1);
    }
  }

  function resize(id) {
    const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);
    if (element !== null) {
      return element.clientHeight;
    } else {
      return 0;
    }
  }

  function showTable(name, row, id) {
    const newTableResults = { ...tableResults };
    newTableResults[name] = true;
    setTableResults(newTableResults);

    if (id) {
      setTimeout(() => {
        scrollToPosition(id);
      }, 100);
    }
  }

  const nav = [
    { label: "Pendentes", name: "pending" },
    { label: "Pagas", name: "paid" },
    { label: "Canceladas", name: "canceled" },
  ];
  
  const blocks = [
    { 
      name: "pending", 
      block: (
        <DropdownArea 
          label="Pendentes" 
          id="pending" 
          activeId={activeId}
          height={height}
          onShow={isDropdownArea}
          className="BlockNav__body--pending"
        >
          <Pending tableView={tableResults.pending} data={pending} list={list} onShowTable={showTable} />
        </DropdownArea>
      )
    },
    { 
      name: "paid", 
      block: (
        <DropdownArea 
          label="Pagas" 
          id="paid" 
          activeId={activeId}
          height={height}
          onShow={isDropdownArea}
          className="BlockNav__body--paid"
        >
          <Paid tableView={tableResults.paid} data={approved} onShowTable={showTable} />
        </DropdownArea>
      )
    },
    { 
      name: "canceled", 
      block: (
        <DropdownArea 
          label="Canceladas" 
          id="canceled" 
          activeId={activeId}
          height={height}
          onShow={isDropdownArea}
          className="BlockNav__body--canceled"
        >
          <Canceled tableView={tableResults.canceled} data={cancelled} onShowTable={showTable} />
        </DropdownArea>
      )
    },
  ];

  return (
    <main className="FinancialView">
      <BlockNav 
        navigation={nav}
        activeNavigation={1}
        blocks={blocks}
      />
    </main>
  );
}

export default FinancialView;