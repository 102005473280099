// LIBs
import React, { useState } from "react";
import { toast } from "react-toastify";

// CONFIG
import { setForget } from '../../../core/api';

// HELPERs
import cn from "../../../core/helpers/cn";

// COMPONENTs
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const FormAccountForgot = (props) => {
  const [ email, setEmail ] = useState("");

  async function handleClick() {
    eventToast(email, "Preencha o E-mail");
    
    if (email) {
      let params = {
        email: email
      }

      let req = await setForget(params);
      
      if (req.data) {
        toast.success("Enviamos um e-mail para você", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          props.setStep('login');
        }, 3000); 
      }

      if (req.error) {          
        toast.error(req.error.title, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  function eventToast(value, text) {
    if(!value) {
      toast.error(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }
  }

  return (
    <div className={cn("FormAccountForgot", props.className)}>
      <div className="FormAccountForgot__inputs">
        <Input 
          label="E-mail" 
          type="email" 
          className="FormAccountForgot__inputs--control" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="FormAccountLogin__button">
        <Button type="button" onClick={handleClick}>Enviar</Button>
      </div>
    </div>
  );
}

export default FormAccountForgot;
