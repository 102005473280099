// COMPONENTs
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import EditorWorld from '../../../components/EditorWorld';
import Grid from "../../../components/Grid";

// ICONs
import { FiPlus, FiTrash } from "react-icons/fi";
import { useEffect } from "react";

export default (props) => {

  useEffect(()=>{
    setTabsHeith()
    window.addEventListener('resize', setTabsHeith);
    return () => window.removeEventListener('resize', setTabsHeith);
  },[])

  const setTabsHeith = () => {
    let tabsEl = document.querySelector(".Tabs__header")
    tabsEl.style.width = "0px"
    tabsEl.style.width = `${document.querySelector(".Tabs__body").offsetWidth}px`
  }

  return (
    <div className="Item">
      <div className="Tabs">
        <div className="Tabs__header">
          <div className="Tabs__header--list">
            { props.data.length > 0 &&
              props.data.map((el, key) => {
                let tabsOptionsClass = "TabsOptions";

                if (key === props.tab) tabsOptionsClass += " active-option"

                return (
                  <div 
                    className={tabsOptionsClass} 
                    onClick={() => props.onSelect(key)}
                    key={key}
                  >
                    <p>{props.block.title}-{(key + 1) < 10 ? `0${key + 1}` : key + 1}</p>
                  </div>
                )
              })
            }
          </div>

          <div className="Tabs__header--add" onClick={props.onAdd}>
            <FiPlus />
          </div>
        </div>

        <div className="Tabs__body">
          { props.data.length > 0 &&
            <div className="Tabs__body--content">
              <div className="Inputs">
                { props.data[props.tab].length > 0 &&
                  props.data[props.tab].map((item, index) => {
                    if (item.type !== "textarea" && item.type !== "file" && item.type !== "files" && item.type !== "texts") {
                      return (
                        <Input  
                          type={item.type}
                          label={item.placeholder}
                          value={item.value}
                          onChange={(event) => props.onChange(event.target.value, index, props.tab)}
                          key={index}
                        />
                      )
                    } else if (item.type === "file") {
                      let fileClass = "SelectFile";

                      if (item.value && item.file) fileClass += " active-file";

                      return (
                        <div 
                          onClick={() => props.onChangeFile(index)}
                          className={fileClass} 
                          key={index}
                        >
                          <p>{item.placeholder}</p>
                          { (item.value && item.file) &&
                            <>
                              <span>{item.value}</span>
                              <img src={item.file} alt="" />
                            </>
                          }
                        </div>
                      );
                    } else if (item.type === "textarea") {
                      return <EditorWorld 
                        editor={item.editor}
                        value={item.value || ""}
                        onChange={(event) => props.onChange(event, index, props.tab)}
                        onToggle={(event) => props.onToggleTextArea(index, props.tab)}
                        editorLoaded={item.loading}
                        key={index}
                      />
                    } else if (item.type === "files") {
                      const files = [ ...item.file ];
                      files.push(null);

                      return <div className="GroupFiles" key={index}>
                        <table width="100%">
                          <tbody>
                            {
                              files.map((el, key) => {
                                let fileClass = "SelectFile";

                                if (el !== null && el.value && el.file) fileClass += " active-file";

                                return (
                                  <tr key={key}>
                                    <td width="90%">
                                      <div 
                                        onClick={() => props.onChangeFile(index)}
                                        className={fileClass}
                                        key={index}
                                      >
                                        <p>{item.placeholder}</p>
                                        { (el !== null && el.value && el.file) &&
                                          <>
                                            <span>{el.value}</span>
                                            <img src={el.file} alt="" />
                                          </>
                                        }
                                      </div>
                                    </td>
                                    <td align="center">
                                      <button onClick={() => props.onDelFileGroup(index, key)}>
                                        <FiTrash />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    } else if (item.type === "texts") {
                      return (
                        <div className="GroupTexts" key={index}>
                          <table width="100%">
                            <tbody>
                              {
                                item.values.map((el, key) => (
                                  <tr key={key}>
                                    <td width="90%">
                                      <Input  
                                        type="text"
                                        label={item.placeholder}
                                        value={el || ""}
                                        onChange={(event) => props.onChange(event.target.value, index, props.tab, key)}
                                      />
                                    </td>
                                    <td align="center">
                                      <button onClick={() => props.onAddTextsGroup(index, props.tab)}>
                                        <FiPlus />
                                      </button>
                                      <button onClick={() => props.onDelTextsGroup(index, props.tab, key)}>
                                        <FiTrash />
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      );
                    }
                  })
                }
              </div>

              <div className="Buttons">
                <Button 
                  type="button"
                  onClick={props.onSend}
                >
                  Salvar
                </Button>

                <Button 
                  type="button"
                  border="outlined"
                  onClick={props.onDel}
                >
                  Excluir
                </Button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}