// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// CONFIG
import { allUsers } from "../../core/api";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// CONTAINERs
import User from "../../containers/Users/User";

// REDUCERs
import { allUser } from "../../core/reducers/UserReducer/selectors";

// HELPERs
import toast from "../../core/helpers/toast";
import { validate } from "../../core/helpers/validate";

const INITIAL_BLOCK = {
    list: [],
    count_pages: 1,
    current_page: 1,
};

const UsersView = (props) => {
    const navigate = useNavigate();
    const [activeId, setActiveId] = useState("");
    const [height, setHeight] = useState(1);
    const [users, setUsers] = useState(INITIAL_BLOCK);
    const [user, setUser] = useState({});
    const [pagePerView, setPagePerView] = useState(3);
    const [orderTableBy, setOrderTableBy] = useState("id");
    const [ order, setOrder ] = useState("asc");

    useEffect(() => {
        validate(navigate);
        getUsers();
    }, [pagePerView, orderTableBy, order]);

    useEffect(() => {
        props.onShownLoad();
    }, []);

    useEffect(() => {
        toggleDropdown(activeId);
    }, [users]);

    function isDropdownArea(id) {
        if (activeId === "") {
            toggleDropdown(id);
        } else {
            if (activeId !== id) {
                toggleDropdown(id);
            } else {
                toggleDropdown();
            }
        }
    }

    function toggleDropdown(id = "") {
        if (id !== "") {
            const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

            if (element !== null) {
                setHeight(element.clientHeight);
                setActiveId(id);
            }
        } else {
            setActiveId("");
            setHeight(1);
        }
    }

    async function getUsers(page = 1) {
        const req = await allUsers({ 
            page: page, 
            limit: pagePerView, 
            order:order,
            orderby: orderTableBy 
        });

        if (req.error && req.error.title) {
            toast("error", req.error.title);
        }

        if (req.data && req.data.list && req.data.list.length > 0) {
            setUsers(req.data);
        }
        props.onHideLoad();
    }

    function switchOrder (){
        setOrder(prev=> prev === "asc" ? "desc" : "asc")
    }

    return (
        <main className="UsersView">
            <DropdownArea
                label="Usuário"
                id="users"
                activeId={activeId}
                height={height}
                onShow={isDropdownArea}
                className="UsersView__blocks"
            >
                <User
                    onFilter={(filter)=>{
                        if (filter === orderTableBy) {
                        switchOrder();
                        } else {
                        setOrder("asc");
                        setOrderTableBy(filter);
                        }
                    }}
                    filter={orderTableBy}
                    order={order}
                    setPageQty={(e) => setPagePerView(+e)}
                    data={users}
                    user={user}
                    setUser={setUser}
                    onRequest={() => getUsers(users.current_page)}
                    onEdit={(row) => setUser(row)}
                    onPage={(page) => getUsers(page)}
                    userCurrent={props.user}
                />
            </DropdownArea>
        </main>
    );
};

const mapStateToProps = (state) => ({
    user: allUser(state),
});

export default connect(mapStateToProps)(UsersView);
