// LIBs
import React, { useState, useEffect } from "react";

// CONFIG
import { getZipCodeData, registerAddress, updateAddress } from '../../../core/api';

// COMPONENTs
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";

// HELPERs
import toast from "../../../core/helpers/toast";

let searchCode = null;

const FormHomeAddress = (props) => {
  const [ id, setId ] = useState("");
  const [ cep, setCep ] = useState("");
  const [ street, setStreet ] = useState("");
  const [ district, setDistrict ] = useState("");
  const [ complement, setComplement ] = useState("");
  const [ city, setCity ] = useState("");
  const [ state, setState ] = useState("");

  useEffect(() => {
    clearTimeout(searchCode);
    searchCode = setTimeout(() => {
      if (cep.length === 9 && cep.match(/([0-9]{5})(\-)([0-9]{3})/g) !== null) {
        getZipCode(cep);
      }
    }, 1000);
  }, [cep]);

  useEffect(() => {
    if (props.data) {
      const data = props.data;

      setId(data.id);
      setCep(data.zipcode);
      setStreet(data.street);
      setDistrict(data.district);
      setComplement(data.complement || "");
      setCity(data.city);
    }
  }, [props.data])

  async function getZipCode(value) {
    if (cep) {
      let req = await getZipCodeData(cep);

      if (req.data && req.data.cep) {
        if (req.data.logradouro) {
          setStreet(req.data.logradouro);
        }
        if (req.data.bairro) {
          setDistrict(req.data.bairro);
        }
        if (req.data.localidade) {
          setCity(req.data.localidade);
        }
        if (req.data.uf) {
          setState(req.data.uf);
        }
      } else {
        setStreet("");
        setDistrict("");
        setCity("");
        setState("");
      }
  
      if (req.data === null) {
        eventToast(false, "Código Invalído!");
      }
    }
  }

  async function sendRegister() {
    isVerifyInputs();

    if (cep && street && district && city && state) {
      let params = {
        zipcode: cep,
        street,
        district,
        complement,
        city,
        region: state,
        country: "Brasil",
      };

      const req = await registerAddress(params);

      if (req.data) {
        props.onLoad();
        toast("success", "Registrado com sucesso!");
      } else {
        toast("error", "Houve um erro ao tentar registrar");
      }
    }
  }

  async function sendEdit() {
    isVerifyInputs();

    if (cep && street && district && city && state) {
      let params = {
        zipcode: cep,
        street,
        district,
        complement,
        city,
        region: state,
        country: "Brasil",
      };

      const req = await updateAddress(id, params);
      
      if (req.data) {
        props.onLoad();
        toast("success", "Atualizado com sucesso!");
      } else {
        console.error(req);
        toast("error", "Houve um erro ao tentar atualizar");
      }
    }
  }

  function isVerifyInputs() {
    eventToast(cep, "Preencha o CEP corretamente!");
    eventToast(street, "Preencha a Rua corretamente!");
    eventToast(district, "Preencha o Bairro corretamente!");
    eventToast(city, "Preencha a Cidade corretamente!");
    eventToast(state, "Preencha o Estado corretamente!");
  }

  function eventToast(value, text) {
    if(!value) {
      toast("error", text);
    }
  }

  return (
    <div className="FormHomeAddress">
      <div className="FormHomeAddress__inputs">
        <div className="FormHomeAddress__inputs--duo">
          <Input 
            libInput="mask"
            label="CEP: *" 
            className="InputDuo" 
            value={cep}
            onChange={(e) => setCep(e.target.value)}
            mask="99999-999"
          />

          <Input 
            label="Rua: *" 
            className="InputDuo" 
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </div>

        <div className="FormHomeAddress__inputs--duo">
          <Input 
            label="Bairro: *" 
            className="InputDuo" 
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
          />

          <Input 
            label="Complemento:" 
            className="InputDuo" 
            value={complement}
            onChange={(e) => setComplement(e.target.value)}
          />
        </div>

        <div className="FormHomeAddress__inputs--duo">
          <Input 
            label="Cidade: *" 
            className="InputDuo" 
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />

          <Select label="Estado: *" value={state} onChange={(e) => setState(e.target.value)}>
            <option>{state}</option>
          </Select>
        </div>
      </div>

      <div className="FormHomeAddress__button">
        <Button 
          type="button" 
          onClick={id ? sendEdit : sendRegister}
        >
          { id ?
            "Editar"
            :
            "Salvar"
          }
        </Button>
      </div>
    </div>
  );
}

export default FormHomeAddress;