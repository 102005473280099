// LIBs
import React from "react";
import { toast } from "react-toastify";

// HELPERs
import cn from "../../core/helpers/cn";

// ICONs
import { MdContentCopy } from "react-icons/md";

export default props => {
  function onCopy() {
    toast.success("Texto copiado com sucesso!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    navigator.clipboard.writeText(props.value);
  }

  return (
    <div className={cn("TextField", props.className)}>
      <span>{props.label}</span> 
      { props.private &&
        <input 
          type={props.hidden ? "password" : "text"} 
          value={
            (props.minCarac && props.minCarac !== null && props.value.toString().length > props.minCarac) ? 
              props.value.substring(0, props.minCarac)+"..." 
            : 
              props.value
          } 
          onChange={() => {}}
          readOnly 
        /> 
      }
      { !props.private && 
        <span>
          { (props.minCarac && props.minCarac !== null) ? 
              props.value && props.value.toString().length > props.minCarac ? 
              props.value && props.value.toString().substring(0, props.minCarac)+"..." 
              : 
                props.value
            : 
              props.value
          }
        </span>
      }
      { props.copy &&
        <button onClick={onCopy}><MdContentCopy /></button>
      }
    </div>
  )
};