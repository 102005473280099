//LIBs
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";

const Arrow = (props) => {
    return (
      <div
        style={props.style}
        onClick={props.onClick}
        className={`arrow-container ${props.responsive && "responsive"} ${props.type === "left" && "left"}`}
      >
        {props.type === "left" ? <FiArrowLeft /> : <FiArrowRight />}
      </div>
    );
  };
  
export default Arrow;
