// LIBs
import { useState, useEffect } from "react";

// COMPONENTs
import Input from "../../components/Input";
import Button from "../../components/Button";
import Grid from "../../components/Grid";

const FormMailMarketing = (props) => {
  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");

  useEffect(() => {
    if (props.data) {
      setName(props.data.name);
      setEmail(props.data.email);
      setPhone(props.data.phone);
    } else {
      clear();
    }
  }, [props.data])

  function send() {
    if (name.trim() && email.trim() && phone.trim()) {
      props.onSend(name, email, phone);
      clear();
    }
  }

  function clear() {
    setName("");
    setEmail("");
    setPhone("");
  }

  return (
    <div className="FormMailMarketing">
      <div className="FormMailMarketing__inputs">
        <Grid>
          <Input 
            label="Nome Completo: *" 
            className="InputDuo" 
            value={name}
            onChange={event => setName(event.target.value)}
          />
          <Input 
            label="E-mail: *" 
            className="InputDuo" 
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <Input 
            libInput="mask"
            label="Telefone: *" 
            className="InputDuo" 
            mask="(99) 9 9999-9999"
            value={phone}
            onChange={event => setPhone(event.target.value)}
          />
        </Grid>
      </div>

      <div className="FormMailMarketing__buttons">
        <Button type="button" onClick={send}>
          { props.data ?
              "Salvar contato"
            :
              "Adicionar novo contato"
          }
        </Button>
        <Button type="button" onClick={props.onExport} border="outlined">Exportar</Button>
        <label>
          Importar
          <input type="file" onChange={event => props.onSendFile(event.target.files[0])} />
        </label>
        {/* <Button type="button" border="outlined">Importar</Button> */}
        { props.data &&
          <Button type="button" onClick={props.onCancel} border="outlined">Cancelar</Button>
        }
      </div>
      
      <div className="FormMailMarketing__content">
        {props.children}
      </div>
    </div>
  )
}

export default FormMailMarketing;