// COMPONENTs
import Table from "../../components/Table";
import Input from "../../components/Input";

// FORMs
import FormMailMarketing from "../../Forms/MailMarketing";

export default props => {
  const collumns = [
    {name: "checked", label: "Checado", children: renderChecked },
    {name: "name", label: "Nome Completo"},
    {name: "email", label: "E-mail", minCarac: 16},
    {name: "phone", label: "Telefone"},
  ];
  
  function renderChecked(checked, index, row) {
    if (checked !== undefined) {
      return (
        <Input 
          type="checkbox"
          checked={checked}
          onChange={() => props.onToggleChecked(index)}
        />
      )
    } else {
      return false;
    }
  }

  return (
    <div className="MailMarketing">
      <FormMailMarketing 
        data={props.emailSelected} 
        onSend={props.onSend}
        onSendFile={props.onSendFile}
        onCancel={() => props.onEmailSelected(null)} 
        onExport={props.onExport}
      >
        <Input 
          type="checkbox"
          label="Selecionar Todos"
          checked={props.allChecked}
          onChange={props.onAllChecked}
          className="CheckboxAll"
        />

        <Table 
          onFilter={props.onFilter}
          filter={props.filter}
          order={props.order}
          setPageQty={props.setPageQty}
          collumns={collumns}
          rows={props.data.list}
          navigation={true}
          countPages={props.data.count_pages}
          currentPage={props.data.current_page}
          onEdit={(row, index) => props.onEmailSelected(index)}
          onDel={(row, index) => props.onEmailDel(index)}
          onPage={props.onPage}
        />
      </FormMailMarketing>
    </div>
  )
}