// CONFIG
import { allConfig } from "../../api";

// REDUCER
import * as types from "./types";

export const updateLang = (lang) => {
  return {
    type: types.UPDATE_LANG_APP,
    payload: lang,
  };
}

export const toggleMode = () => {
  return {
    type: types.TOGGLE_MODE_APP,
  };
}

export const updateScrollTop = (scrollTop) => {
  return {
    type: types.UPDATE_SCROLL_TOP_APP,
    payload: scrollTop,
  };
}

export const updateScreens = (screenWidth, screenHeight) => {
  return {
    type: types.UPDATE_SCREENS_APP,
    payload: {
      screenWidth,
      screenHeight,
    },
  };
}

export const setAllConfig = async (data) => ({ 
  type: types.SET_ALL_CONFIG, 
  payload: data 
});