/* eslint-disable no-restricted-globals */

// LIBs
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// COMPONENTs
import Button from "../Button";
import Modal from "../Modal";
import TextField from "../TextField";
import PlanCard from "../PlanCard";
import Arrow from "../Arrow";

// CONFIG
import { getPlans, registerCheckout, getOrderStatus } from "../../core/api";

// UTILs
import toast from "../../core/helpers/toast";

// ACTIONs
import { changeUser } from "../../core/reducers/UserReducer/actions";

let intervalObserver;

const UpgradeButton = (props) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [plans, setPlans] = useState([]);
    const [currentPlan, setCurrentPlan] = useState([]);
    const [plan, setPlan] = useState([]);
    const [user, setUser] = useState([]);
    // checkout
    const [qr_code_base64, setQrCode64] = useState();
    const [copy_qr_code, setCopyQrCode] = useState();
    const [statusOrder, setStatusOrder] = useState(false);
    const [planPeriod, setPlanPeriod] = useState();
    const periodsSlider = useRef();

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        if (statusOrder) {
            setVisibleModal(false);
            window.localStorage.removeItem("order_id");
            toast("success", "Pagamento aprovado com sucesso!");
            setStatusOrder(false);

            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }, [statusOrder]);

    async function observer() {
        if (localStorage.getItem("order_id")) {
            intervalObserver = setInterval(async () => {
                const id = localStorage.getItem("order_id");
                let req = await getOrderStatus(id);

                if (req.data.status) {
                    setStatusOrder(true);
                    clearInterval(intervalObserver);
                }
            }, 5000);
        }
    }

    /**
     * requests
     */
    async function getList() {
        let req = await getPlans();
        let filteredPlans = req.data.customer.filter((plan) => plan.price !== 0);
        setPlans(filteredPlans);

        let user = await props.changeUser();

        if (user.payload.data) {
            setUser(user.payload.data);
            setCurrentPlan(user.payload.data.plan);
        }
    }

    function upgrade() {
        setVisibleModal(true);
    }

    /**
     * handleClick
     */
    async function handleClick() {
        if (plan.id) {
            let params = {
                pix: true,
                plan_id: plan.id,
                price: plan.price,
                period: planPeriod,
            };

            let message = `<span style="font-weight: bold">Ao alterar o plano, você poderá perder alguns recursos.</span><br><br>`;
            message += `<span style="font-weight: bold">Plano atual:</span> ${currentPlan.name} - ${currentPlan.webstores_quantity} Webstores<br>`;
            message += `<span style="font-weight: bold">Novo plano:</span> ${plan.name} - ${plan.webstores_quantity} Webstores<br><br>`;
            message += `Caso tenha Webstores ativos além do seu plano escolhido serão inativados os ultimos registrados deixando apenas ativos os mais antigos.<br><br>`;
            message += `Deseja continuar?`;

            Swal.fire({
                title: "Confirmação",
                html: message,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    // Ação para confirmado
                    auxHandleClick(params);
                } else {
                    // Ação para cancelado
                    toast("warning", "Alteração cancelada!");
                    setVisibleModal(false);
                }
            });
        } else {
            toast("error", "Nenhum plano selecionado");
        }
    }

    async function auxHandleClick(params) {
        let req = await registerCheckout(params);

        if (req.error.title) {
            toast("error", req.error.title);
            return;
        }

        if (req.data.result_mp) {
            window.localStorage.setItem("order_id", req.data.find.id);
            setQrCode64(req.data.result_mp.transaction_data.qr_code_base64);
            setCopyQrCode(req.data.result_mp.transaction_data.qr_code);
            observer();
        }

        if (req.data.find) {
            if (req.data.find.amount == 0) {
                await props.changeUser();
                setVisibleModal(false);
                toast("success", "Você agora esta no plano gratuito!");
                return;
            }
        }
    }

    async function handleClickPlanFree() {
        let req = await getPlans();
        let planFree = req.filter((plan) => plan.price === 0);

        let params = {
            pix: true,
            plan_id: planFree[0].id,
            price: planFree[0].price,
        };

        let message = `<span style="font-weight: bold">Ao alterar o plano, você poderá perder alguns recursos.</span><br><br>`;
        message += `<span style="font-weight: bold">Plano atual:</span> ${currentPlan.name} - ${currentPlan.webstores_quantity} Webstores<br>`;
        message += `<span style="font-weight: bold">Novo plano:</span> ${planFree[0].name} - ${planFree[0].webstores_quantity} Webstore<br><br>`;
        message += `Caso tenha Webstores ativos além do seu plano escolhido serão inativados os ultimos registrados deixando apenas ativos os mais antigos.<br><br>`;
        message += `Deseja continuar?`;

        Swal.fire({
            title: "Confirmação",
            html: message,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                auxHandleClick(params);
            } else {
                toast("warning", "Alteração cancelada!");
                setVisibleModal(false);
            }
        });
    }

    /**
     *
     * @param {*} periodo
     * @returns string
     */
    function traduzirPeriodo(periodo) {
        const traducoes = {
            monthly: "Mensal",
            quarterly: "Trimestral",
            semi_annual: "Semestral",
            yearly: "Anual",
        };

        return traducoes[periodo] || periodo;
    }

    function closeModal() {
        setVisibleModal(false);
        setTimeout(() => {
            setQrCode64();
            setCopyQrCode();
            setStatusOrder(false);
            setPlanPeriod();
            setPlan([]);
        }, 500);
    }

    const modalSettings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        dotsClass: "Slider-Dots",
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    function next(sliderRef) {
        sliderRef.current.slickNext();
    }

    function previous(sliderRef) {
        sliderRef.current.slickPrev();
    }

    return (
        <div className="Main">
            <div className="Main__container">
                <Modal
                    visible={visibleModal}
                    title={`Upgrade`}
                    onHide={closeModal}
                    className="Main__container--modal"
                >
                    <label htmlFor="">Escolha um Plano</label>
                    <select
                        className="select"
                        value={plan.id ? plan[0] : ""}
                        onChange={(e) => setPlan(JSON.parse(e.target.value))}
                    >
                        <option value="" disabled>
                            Selecione um Plano
                        </option>
                        {plans &&
                            plans.map((el, index) => {
                                const optionValue = JSON.stringify(el);
                                return (
                                    <option key={el.id} value={optionValue}>
                                        {el.name} | {el.webstores_quantity} WebStore |{" "}
                                        {el.price.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </option>
                                );
                            })}
                    </select>

                    {plan && plan.id && (
                        <div className="plan-selected">
                            <p className="title">
                                SELECIONE O PERIODO PARA O SEU PLANO {plan.name}
                            </p>

                            <div className="periods">
                                <Arrow onClick={() => next(periodsSlider)} />
                                <Arrow onClick={() => previous(periodsSlider)} type="left" />
                                <Slider ref={periodsSlider} {...modalSettings}>
                                    {plan.periods.map((el, key) => (
                                        <div className="period">
                                            <PlanCard
                                                key={key}
                                                plan={{
                                                    name: traduzirPeriodo(el.period),
                                                    periods: traduzirPeriodo(el.period),
                                                    price: el.price,
                                                }}
                                                btnLabel={"Selecionar Periodo"}
                                                onClick={() => setPlanPeriod(el.period)}
                                                selected={
                                                    planPeriod !== undefined &&
                                                    planPeriod !== el.period
                                                }
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    )}

                    <Button type="button" onClick={handleClick}>
                        Efetuar Pagamento
                    </Button>

                    {qr_code_base64 && (
                        <div className="payment">
                            <p>Para efetuar o pagamento</p>
                            <h4>Leia o QR Code ou Copie</h4>
                            <img
                                width="100%"
                                src={`data:image/jpeg;base64,${qr_code_base64}`}
                                alt="QR Code"
                            />
                            <TextField value={`${copy_qr_code}`} copy={true} private={false} />
                        </div>
                    )}
                </Modal>
                {/* exibe para user */}
                {user.user_id === null && (
                    <a type="button" onClick={upgrade}>
                        Fazer upgrade
                    </a>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeUser,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(UpgradeButton);
