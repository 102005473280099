// LIBs
import React from "react";
import InputMask from "react-input-mask";

// HELPERs
import cn from "../../core/helpers/cn";

export default props => {
  const { type = "text" } = props;

  switch (props.libInput) {
    case "mask":
      return (
        <label 
          className={
            cn("InputLabel", props.className)
          }
        >
          <InputMask 
            type={type} 
            placeholder={props.placeholder || ""} 
            value={props.value} 
            onChange={props.onChange} 
            readOnly={props.readOnly}
            mask={props.mask}
            {...props.input}
          />
          <span>{props.label}</span>
        </label>
      )
    default:
      if (
          type === "text" || 
          type === "email" || 
          type === "number" || 
          type === "password" || 
          type === "date"
      ) {
        return (
          <label 
            className={
              cn("InputLabel", props.className)
            }
          >
            <input 
              type={type} 
              placeholder={props.placeholder || ""} 
              value={props.value} 
              onChange={props.onChange} 
              readOnly={props.readOnly}
              {...props.input}
            />
            <span>{props.label}</span>
          </label>
        );
      } else if (type === "color") {
        return (
          <label 
            className={
              cn(`ColorLabel`, props.className)
            }
          >
            <p>{props.label}</p>
            <input 
              type={type}  
              checked={props.checked} 
              onChange={props.onChange} 
              {...props.checkbox}
            />
          </label>
        );
      } else if(type === "checkbox") {
        return (
          <label 
            className={
              cn(`CheckboxLabel`, props.className)
            }
          >
            <input 
              type={type}  
              checked={props.checked} 
              onChange={props.onChange} 
              {...props.checkbox}
            />
            <span></span>
            <p>{props.label}</p>
          </label>
        );
      } else if (type === "file") {
        return (
          <label 
            className={
              cn("FileLabel", props.className)
            }
          >
            <input 
              type="file"
              onChange={props.onChangeFile} 
              readOnly={props.readOnly}
              
            />
            <span>{props.label}</span>
            { props.file &&
              <img 
                src={
                  typeof props.file === "string" ? 
                  props.file : 
                  URL.createObjectURL(props.file)
                } 
                alt="Imagem" 
              />
            }
          </label>
        );
      }
  }
};