// LIBs
import React, { useState, useEffect } from "react";

// COMPONENTs
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";

// HELPERs
import toast from "../../../core/helpers/toast";

const FormMenuMenu = (props) => {
  let menuButtonClass = "FormMenuMenu__button";

  const [ idMenu, setIdMenu ] = useState(null);
  const [ title, setTitle ] = useState("");
  const [ webstore, setWebstore ] = useState("");
  const [ link, setLink ] = useState("");
  const [ menu, setMenu ] = useState("");

  useEffect(() => {
    if (props.data) {
      const data = props.data;

      if(data.id && data.title && data.link && data.webstore_id) {
        setIdMenu(data.id);
        setTitle(data.title);
        setLink(data.link);
        setWebstore(data.webstore_id);
        if (data.menu_id) {
          setMenu(data.menu_id);
        }
      }
    }
  }, [props.data])

  if (idMenu) menuButtonClass += " grid-button";

  function clear() {
    setIdMenu(null);
    setTitle("");
    setWebstore("");
    setLink("");
    setMenu("");
    props.setMenu("");
  }

  function send() {
    if (!title) toast("warning", "Preencha campo de título");
    if (!webstore || webstore === "null") toast("warning", "Selecione uma webstore");
    if (!link) toast("warning", "Preencha campo de link");

    if (title && (webstore && webstore !== "null") && link) {
      props.onSend(idMenu, title, webstore, link, menu);
      clear();
    }
  }

  return (
    <div className="FormMenuMenu">
      <div className="FormMenuMenu__inputs">
        <div className="FormMenuMenu__inputs--duo">
          <Input 
            type="text"
            className="InputDuo"
            label="Titulo: *"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <Select
            label="Webstores: *"
            className="SelectDuo"
            value={webstore}
            onChange={(e) => setWebstore(e.target.value)}
          >
            <option value="null">Selecione Webstores</option>
            { (props.webStores && props.webStores.length > 0) &&
              props.webStores.map((element, index) => (
                <option 
                  value={element.id} 
                  key={index}
                >
                  {element.name}
                </option>
              ))
            }
          </Select>
        </div>

        <div className="FormMenuMenu__inputs--duo">
          <Input 
            type="text"
            className="InputDuo"
            label="Link: *"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />

          <Select
            label="Sub-menu:"
            className="SelectDuo"
            value={menu}
            onChange={(e) => setMenu(e.target.value)}
          >
            <option value="null">Selecione Menu</option>
            { (props.menus && props.menus.length > 0) &&
              props.menus.map((element, index) => (
                <option 
                  value={element.id} 
                  key={index}
                >
                  {element.title}
                </option>
              ))
            }
          </Select>
        </div>
      </div>

      <div className={menuButtonClass}>
        <Button type="button" onClick={send}>
          { (props.data && props.data.id) ?
            "Salvar"
            :
            "Registrar"
          }
        </Button>
        { props.data.id &&
          <Button 
            type="button" 
            border="outlined"
            onClick={() => {
              clear();
            }}
          >
            Cancelar
          </Button>
        }
      </div>

      <div className="FormMenuMenu__content">
        {props.children}
      </div>
    </div>
  );
}

export default FormMenuMenu;