// LIBs
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

// STORE
import store from "./core/store";

// ROUTER
import Router from "./core/Router";

// REDUCERs
import * as appActions from "./core/reducers/AppReducer/actions";

// STYLEs
import "./core/StyleSheets/extends.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router />
  </Provider>
);

window.addEventListener("scroll", (e) => {
  store.dispatch(appActions.updateScrollTop(window.scrollY));
});

window.addEventListener("resize", (e) => {
  store.dispatch(appActions.updateScreens(window.innerWidth, window.innerHeight));
});
