// LIBs
import { encode, decode } from "js-base64";

// REDUCER
import * as types from "./types";

const userName = "@pageapi:user";

const INITIAL_STATE = {
  id: null,
  cpf: "",
  group_id: null,
  group: null,
  plan_id: null,
  plan: null,
  user_id: null,
  rg: "",
  firstname: "",
  lastname: "",
  period: "",
  folder: null,
  phone: "",
  email: "",
  email_verified_at: null,
  state_tax: null,
  validate: "",
  document: null,
  is_active: null,
  users_webstores_view: [],
  type: null,
  token: "",
  created_at: "",
  updated_at: "",
};

const user = localStorage.getItem(userName);

function UserReducer(state = INITIAL_STATE, action) {
  let newState = { ...state };

  if (user) {
    Object.assign(newState, JSON.parse(decode(user)));
  }
  
  switch(action.type) {
    case types.CHANGE_USER:
      const keysPayload = Object.keys(action.payload.data);
      keysPayload.forEach((item) => {
        if (newState[item] !== undefined) {
          newState[item] = action.payload.data[item];
        }
      });
      localStorage.setItem(userName, encode(JSON.stringify(action.payload.data)));
      return newState;
    case types.CLEAR_USER:
      newState = INITIAL_STATE;
      localStorage.removeItem(userName);
      return newState;
    default:
      return newState;
  }
}

export default UserReducer;