// LIBs
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// COMPONENTs
import Input from "../../components/Input";
import Button from "../../components/Button";
import Table from "../../components/Table";
import Select from "../../components/Select";

// CONFIG
import { getWebStores, registerWebStore, updateWebStore, deleteWebStore, getAllUsers } from '../../core/api';

// UTILs
import toast from "../../core/helpers/toast";

// REDUCERs
import { planUser } from "../../core/reducers/UserReducer/selectors";

const collumns = [
  { label: "ID", name: "id" },
  { label: "Nome", name: "name" },
  { label: "Token", name: "token", copy: true, minCarac: 16 },
  { label: "Identificador", name: "url_key", minCarac: 16 },
  { label: "Site", name: "site" },
  { label: "Site Homol", name: "site_homol" },
  { label: "Site Local", name: "site_local" },
  { label: "Status", name: "status" },
];

const FormWebstores = (props) => {
  let webstoreButtonClass = "FormWebstores__button";

  const [idWebStore, setIdWebStore] = useState(null);
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [usekey, setUrlKey] = useState("");
  const [site, setSite] = useState("");
  const [site_homol, setSiteHomol] = useState("");
  const [site_local, setSiteLocal] = useState("");
  const [is_active, setIsActive] = useState(true);
  const [rows, setRows] = useState([]);
  const [planStatus, setPlanStatus] = useState(true);
  const [array, setArray] = useState([]);
  const [data, setData] = useState([]);
  const [pagePerView, setPagePerView] = useState(3);
  const [orderTableBy, setOrderTableBy] = useState("id");
  const [showForm, setShowForm] = useState(true);
  const [ order, setOrder ] = useState("asc");

  useEffect(() => {
    getWebStore();
    getUser();
    if(!props.showForm){
      setShowForm(false)
    }else{
      setShowForm(true)
    }
  }, [pagePerView, orderTableBy, order]);

  async function getUser() {
    if (props.plan) {
      const price = props.plan.price;
      const planStatus = price > 0;
      setPlanStatus(planStatus);
    }
  }

  if (idWebStore) webstoreButtonClass += " grid-button";

  async function getWebStore(page = 1) {
    let req = await getWebStores({ 
      page: page, 
      limit: pagePerView, 
      order:order,
      orderby: orderTableBy 
    });

    if (req.data.list) {
      setData(req.data);
      setRows(req.data.list);
    }
  }

  function fillDataWebStore(webstore) {
    let newArray = [];

    if (webstore.users) {
      for (let i = 0; i < webstore.users.length; i++) {
        const element = webstore.users[i];
        newArray.push(element.user);
      }
    }

    setArray(newArray);
    setIdWebStore(webstore.id);
    setName(webstore.name);
    setUrlKey(webstore.url_key);
    setSite(webstore.site);
    setSiteHomol(webstore.site_homol);
    setSiteLocal(webstore.site_local ? webstore.site_local : "");
    setIsActive(webstore.is_active ? webstore.is_active : false);
    setToken(webstore.token);
  }

  function send() {
    if (idWebStore) {
      sendUpdate();
    } else {
      if (planStatus) {
        sendRegister();
      } else {
        toast("warning", "Seu plano é gratuito e não permite fazer mais um registro!");
      }
    }
  }

  async function sendUpdate() {
    const req = await updateWebStore(idWebStore, { name, url_key: usekey, site, site_homol, site_local, users: array });

    if (req.error && req.error.title) {
      toast("error", req.error.title);
    }

    if (req.data && req.data.id) {
      toast("success", req.data.title);
      getWebStore();
      clear();
      setTimeout(() => {
        props.onLoad();
      }, 100);
    }
  }

  /**
   * register webstore
   * register users view webstore - vendedor
   */
  async function sendRegister() {
    if (name && usekey && site && site_homol, site_local) {
      const req = await registerWebStore({ name, url_key: usekey, site, site_homol, site_local, users: array });

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.id) {
        toast("success", req.data.title);
        getWebStore();
        clear();
        setTimeout(() => {
          props.onLoad();
        }, 100)
      }
    } else {
      toast("warning", "Preencha todas as informações corretamente!");
    }
  }

  async function del(webstore) {
    if (webstore.id) {
      const req = await deleteWebStore(webstore.id);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.title) {
        toast("success", req.data.title);
        getWebStore();
        clear();
        setTimeout(() => {
          props.onLoad();
        }, 100)
      }
    }
  }

  function clear() {
    setIdWebStore(null);
    setName("");
    setUrlKey("");
    setSite("");
    setSiteHomol("");
    setSiteLocal("");
    setArray([]);
  }

  function switchOrder (){
    setOrder(prev=> prev === "asc" ? "desc" : "asc")
  }

  return (
    <div className="FormWebstores">

      {showForm && (<>
        <div className="FormWebstores__inputs">
          <div className="FormWebstores__inputs--duo">
            <Input
              type="text"
              className="InputDuo"
              label="Titulo: *"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <Input
              type="text"
              className="InputDuo"
              label="Identificação: *"
              value={usekey}
              onChange={(e) => setUrlKey(e.target.value)}
            />
          </div>
          <div className="FormWebstores__inputs--duo">
            <Input
              type="text"
              className="InputDuo"
              label="Site: *"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            />

            <Input
              type="text"
              className="InputDuo"
              label="Site Homol: *"
              value={site_homol}
              onChange={(e) => setSiteHomol(e.target.value)}
            />
          </div>
          <div className="FormWebstores__inputs--duo">
            <Input
              type="text"
              className="InputDuo"
              label="Site Local: *"
              value={site_local}
              onChange={(e) => setSiteLocal(e.target.value)}
            />

            <Select
              label="Status"
              className="type"
              onChange={(e) => setIsActive(e.target.value)}
              value={is_active}
            >
              <option value={1}>Ativo</option>
              <option value={0}>Inativo</option>
            </Select>
          </div>
        </div>

        <div className="FormMenuMenu__content">
          {props.children}
        </div>

        <div className={webstoreButtonClass}>
          <Button type="button" onClick={send}>
            {idWebStore ?
              "Salvar"
              :
              "Registrar"
            }
          </Button>
          {idWebStore &&
            <Button
              type="button"
              border="outlined"
              onClick={() => {
                clear();
              }}
            >
              Cancelar
            </Button>
          }
        </div>
      </>)}

      <h3><i>Total: {data.count} Webstores</i></h3>
      <div className="FormWebstores__list">
        <div className="TableArea">
          <Table
            onFilter={(filter)=>{
              if (filter === orderTableBy) {
                switchOrder();
              } else {
                setOrder("asc");
                setOrderTableBy(filter);
              }
            }}
          filter={orderTableBy}
          order={order}
            setPageQty={(e) => { setPagePerView(e) }}
            collumns={collumns}
            rows={rows}
            navigation={true}
            countPages={data.count_pages}
            currentPage={data.current_page}
            onEdit={showForm && fillDataWebStore}
            onDel={showForm && del}
            onPage={page => getWebStore(page)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  plan: planUser(state),
});

export default connect(mapStateToProps)(FormWebstores);