// LIBs
import React, { useState, useEffect } from "react";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// CONTAINERs
import MailMarketing from "../../containers/MailMarketing";

// CONFIG
import { 
  allMailMarketing, 
  addMailMarketing, 
  updateMailMarketing,
  deleteMailMarketing,
  uploadMarketing,
  exportMarketing
} from "../../core/api";

// HELPERs
import toast from "../../core/helpers/toast";

const INITIAL_BLOCK = {
  list: [],
  count_pages: 1,
  current_page: 1,
};

const MailMarketingView = (props) => {
  const [ activeId, setActiveId ] = useState("");
  const [ data, setData ] = useState(INITIAL_BLOCK);
  const [ emailSelected, setEmailSelected ] = useState(null);
  const [ all, setAll ] = useState(false);
  const [ pagePerView, setPagePerView ] = useState(3);
  const [ orderTableBy, setOrderTableBy ] = useState("id");
  const [ order, setOrder ] = useState("asc");

  useEffect(() => {
    props.onShownLoad()
    getAllMailMarketing();
  }, [pagePerView, orderTableBy, order])
  
  useEffect(() => {
    if (data.list.length > 0) {
      let isAllChecked = data.list.map(e => e.checked).reduce((a, b) => !(!a || !b));
      
      if (!isAllChecked) {
        setAll(false);
      }
    }
  }, [data])

  async function getAllMailMarketing(page = 1) {
    const req = await allMailMarketing({
      page:page, 
      limit:pagePerView, 
      order:order,
      orderby: orderTableBy
    });

    if (req.error && req.error.title) {
      toast("error", req.error.title);
    }

    if (req.data && req.data.list && req.data.list.length > 0) {
      insertChecked(req.data);
    }

    props.onHideLoad()
  }

  function switchOrder (){
    setOrder(prev=> prev === "asc" ? "desc" : "asc")
  }

  function insertChecked(data) {
    let newData = { ...data };
    let newList = [ ...newData.list ];
    newList = newList.map(row => {
      row.checked = false;
      return row;
    });
    newData.list = newList;
    setData(newData);
  }

  function checkAll() {
    const newData = { ...data };
    let newList = [ ...newData.list ];

    if (!all) {
      newList.forEach(item => item.checked = true);
    } else {
      newList.forEach(item => item.checked = false);
    }

    newData.list = newList;

    setData(newData);
    setAll(!all);
  }

  function toggleCheckedData(index) {
    let newData = { ...data };
    let newList = [ ...newData.list ];
    newList[index].checked = !newList[index].checked;
    newData.list = newList;
    setData(newData);
  }

  async function exportEmails() {
    let newList = data.list.filter(item => item.checked).map(item => item.id);
    let req;

    if (all) {
      req = await exportMarketing({ data: "all", list: [] });
    } else {
      req = await exportMarketing({ data: "", list: newList });
    }

    if (req.data && req.data.url) {
      const link = document.createElement('a');
      link.href = req.data.url;
      link.download = true;

      link.click();
    }
  }

  function send(name, email, phone) {
    if (emailSelected === null) {
      sendAdd(name, email, phone);
    } else {
      sendEdit(data.list[emailSelected].id, name, email, phone);
    }
  }

  async function sendAdd(name, email, phone) {
    await addMailMarketing({ name, email, phone });
    await getAllMailMarketing(data.current_page);
  }

  async function sendEdit(id, name, email, phone) {
    await updateMailMarketing(id, { name, email, phone });
    await getAllMailMarketing(data.current_page);
  }

  async function sendDel(key) {
    if (data.list[key] && data.list[key].id) {
      await deleteMailMarketing(data.list[key].id);  
      let page = data.current_page;

      if (data.list.length === 1 && page !== 0) {
        page -= 1;
      }
      
      await getAllMailMarketing(page);    
    }
  }

  async function sendFile(file) {
    const formData = new FormData();

    formData.append("file", file);

    const req = await uploadMarketing(formData);
    await getAllMailMarketing(data.current_page);

    if (req.error.title) {
      toast('error', req.error.title);
      return;
    }

    toast('success', "Dados importado com sucesso!");
  }

  return (
    <main className="MailMarketingView">
      <DropdownArea
        label="E-mail Marketing" 
        id="mail-marketing" 
        activeId={activeId}
        onShow={id => activeId !== id ? setActiveId(id) : setActiveId("")}
        className="MailMarketingView__blocks"
      >
        <MailMarketing
          onFilter={(filter)=>{
            if (filter === orderTableBy) {
              switchOrder();
            } else {
              setOrder("asc");
              setOrderTableBy(filter);
            }
          }}
          filter={orderTableBy}
          order={order}
          setPageQty={(e)=>setPagePerView(+e)}
          data={data} 
          emailSelected={data.list[emailSelected]}
          allChecked={all} 
          onAllChecked={checkAll}
          onToggleChecked={toggleCheckedData} 
          onEmailSelected={setEmailSelected}
          onSend={send}
          onSendFile={sendFile}
          onEmailDel={sendDel}
          onExport={exportEmails}
          onPage={page => getAllMailMarketing(page)}
        />
      </DropdownArea>
    </main>
  )
}

export default MailMarketingView;