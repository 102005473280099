// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// CONTAINERs
import Gallery from "../../containers/Gallery";

// HELPERs
import { validate } from "../../core/helpers/validate";

// REDUCERs
import { planUser } from "../../core/reducers/UserReducer/selectors";

const GalleryView = (props) => {
  const navigate = useNavigate();
  const [ activeId, setActiveId ] = useState("");
  const [ height, setHeight ] = useState(1);
  const [ planStatus, setPlanStatus ] = useState(true);

  useEffect(() => {
    props.onShownLoad()
    validate(navigate);
    initialize();
  }, []);

  async function initialize() {
    if (props.plan) {
      const price = props.plan.price;
      const planStatus = price > 0;
      setPlanStatus(planStatus);
    }
    props.onHideLoad()
  }

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if(id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

      if (element !== null) {
        setHeight(element.clientHeight);
        setActiveId(id);
      }
    } else {
      setActiveId("");
      setHeight(1);
    }
  }

  return (
    <main className="GalleryView">
      <DropdownArea
        label="Galeria" 
        id="gallery" 
        activeId={activeId}
        height={height}
        onShow={isDropdownArea}
        className="GalleryView__blocks"
      >
        {planStatus ? 
          <Gallery onRequest={() => toggleDropdown(activeId)} /> : 
          <div className="alert">Seu plano é gratuito e não permite fazer registro!</div>
        }
      </DropdownArea>
    </main>
  );
}

const mapStateToProps = (state) => ({
  plan: planUser(state),
});

export default connect(mapStateToProps)(GalleryView);