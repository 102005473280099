import React, { useEffect } from "react";

const ComponentScreem = ({ onShownLoad, onHideLoad }) => {
  useEffect(() => {
    onHideLoad();
  }, []);

  return <h1>Components</h1>;
};

export default ComponentScreem;
