// LIBs
import React from "react";

// HELPERs
import cn from "../../core/helpers/cn";

// ICONs
import { BsChevronDown } from "react-icons/bs";

export default (props) => (
  <div 
    className={cn("DropdownArea", props.className)} 
    id={props.id}
  >
    <div className="DropdownArea__top" onClick={() => props.onShow(props.id)}>
      <div className="DropdownArea__top--container">
        <div className="Title">
          <h3>{props.label}</h3>
        </div>

        <div className={`Arrow${props.id === props.activeId ? " active-arrow" : ""}`}>
          <button><BsChevronDown /></button>
        </div>
      </div>
    </div>

    <div className={`DropdownArea__bottom${props.id === props.activeId ? " active-bottom" : ""}`}>
      <div className="DropdownArea__bottom--container">
        {props.children}
      </div>
    </div>
  </div>
);