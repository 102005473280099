// LIBs
import React from "react";

// HELPERs
import cn from "../../core/helpers/cn";

// IMAGEs
import arrowDown from "../../assets/icons/arrow-down.png";

export default (props) => (
  <label className={cn("SelectLabel", props.className)}>
    <div className="SelectLabel__container">
      <select 
        value={props.value} 
        onChange={props.onChange} 
        style={{backgroundImage: `url(${arrowDown})`, ...props.style}}
        {...props.select}
      >
        {props.children}
      </select>
      <span>{props.label}</span>
    </div>
  </label>
);